import { AfterContentInit, Component, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-table-layout',
  standalone: true,
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.scss'],
  imports: [NgIf, TranslateModule],
})
export class TableLayoutComponent implements AfterContentInit, OnDestroy {
  @Input({ required: false }) set enableVirtualScroll(enableVirtualScroll: boolean) {
    this.enableVirtualScrollClass = enableVirtualScroll;
  }
  private resizeObserver: ResizeObserver | undefined;

  @HostBinding('class.enable-virtual-scroll') public enableVirtualScrollClass = false;

  constructor(private elementRef: ElementRef) {}

  public ngAfterContentInit(): void {
    if (this.enableVirtualScrollClass) {
      this.resizeObserver = new ResizeObserver(() => {
        const element = this.elementRef.nativeElement;
        const table = this.elementRef.nativeElement.querySelector('table');
        element.style.height = `${(table?.offsetHeight ?? 0) + 70}px`;
      });
      const table = this.elementRef.nativeElement.querySelector('table');
      if (table) {
        this.resizeObserver?.observe(table);
      } else {
        const intervalId = setInterval(() => {
          const table = this.elementRef.nativeElement.querySelector('table');
          if (table) {
            this.resizeObserver?.observe(table);
            clearInterval(intervalId);
          }
        }, 500);
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
