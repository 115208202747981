import { Component, Input } from '@angular/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Input() public error = '';
}
