<div
  class="filters-menu-trigger"
  [class.filters-menu-trigger_active]="isFiltersMenuOpen || sortState || filtersCount"
  (click)="toggleFiltersMenu()">
  <div
    class="filters-menu-trigger__sort"
    [class.visible]="sortState"
    [class.filters-menu-trigger__sort_moved]="filtersCount">
    <app-icon [name]="sortState > 0 ? 'arrow-up' : 'arrow-down'"></app-icon>
  </div>

  <div class="filters-menu-trigger__current-filters" [class.visible]="filtersCount">
    {{ filtersCount }}
  </div>

  <app-icon
    class="filters-menu-trigger__filter"
    name="filter-list"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"></app-icon>
</div>

<ng-template
  #filterMenu
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isFiltersMenuOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPositions]="overlayPositions"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="closeFiltersMenu(true)">
  <form class="inline-dialog" [formGroup]="filtersForm" (ngSubmit)="apply()">
    <h2 class="inline-dialog__header">
      {{ 'filters' | translate }}
      <app-icon name="close-outlined" (click)="closeFiltersMenu(true)"></app-icon>
    </h2>
    <div class="inline-dialog__sort">
      <app-radio-button [formControl]="sortControl" [items]="sortValues"></app-radio-button>
    </div>

    <div class="inline-dialog__filters">
      <ng-container *ngFor="let filter of filters">
        <ng-container [ngSwitch]="filter.matchMode">
          <ng-container *ngSwitchCase="MatchMode.SEARCH">
            <app-autocomplete-input
              [items]="data"
              [formControlName]="'search'"
              [placeholder]="'search' | translate"></app-autocomplete-input>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="inline-dialog__footer">
      <button class="inline-dialog__button" (click)="clearFilter()">
        {{ 'reset' | translate }}
      </button>
      <button class="inline-dialog__button" type="submit">{{ 'apply' | translate }}</button>
    </div>
  </form>
</ng-template>
