import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { RouterLink } from '@angular/router';
import { TimeTrackingWatcherService } from '@app/core/services';

@Component({
  selector: 'app-time-tracker',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterLink],
  templateUrl: './time-tracker.component.html',
  styleUrls: ['./time-tracker.component.scss'],
})
export class TimeTrackerComponent {
  public status$ = this.timeTrackingWatcher.status$;

  constructor(private timeTrackingWatcher: TimeTrackingWatcherService) {}
}
