import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDynamicTableHeaderConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-header-config.interface';
import { DynamicTableClass } from '@app/ui-kit/shared/components/tables';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTableService } from '@app/ui-kit/shared/components/tables/dynamic-table/dynamic-table.service';
import { ColumnCollapseComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/column-collapse/column-collapse.component';
import { IsArrayPipe } from '@app/ui-kit/shared/pipes';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-additional-header-collapsible]',
  standalone: true,
  imports: [CommonModule, ColumnCollapseComponent, TranslateModule, IsArrayPipe],
  templateUrl: './dynamic-table-additional-header-collapsible.component.html',
  styleUrls: ['./dynamic-table-additional-header-collapsible.component.scss'],
})
export class DynamicTableAdditionalHeaderCollapsibleComponent implements OnChanges {
  private _isCollapsed = false;
  @Input({ required: true }) public set isCollapsed(isCollapsed: boolean) {
    this._isCollapsed = isCollapsed;
    // update collapsed when table data switched
    if (this.isCollapsed) {
      this.dynamicTableService.onCollapseColumn(this.config.collapsible!, false);
    }
  }
  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  @Input({ required: true }) public config: IDynamicTableHeaderConfig;
  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('class.sticky-th') public stickyThClass = false;
  @HostBinding('class.empty-column') public emptyColumnClass = false;
  @HostBinding('attr.colspan') public colspan: string | number | undefined;
  @HostBinding('style.left.px') public styleLeftPx: number | undefined;

  constructor(private dynamicTableService: DynamicTableService) {}

  public ngOnChanges(): void {
    this.classes = this.config.cssClass;
    this.stickyThClass = !!this.config.sticky;
    this.emptyColumnClass = this.isCollapsed;
    this.colspan = this.isCollapsed ? 1 : this.config.colspan;
    this.styleLeftPx = this.config.sticky?.left;
  }

  public switch(): void {
    if (this.isCollapsed) {
      this.dynamicTableService.onExpandColumn(this.config.collapsible!);
    } else {
      this.dynamicTableService.onCollapseColumn(this.config.collapsible!);
    }
  }
}
