import { IDivisionResponse, ISubDivisionResponse } from '@app/core/api';
import { Division, SubDivision } from '@app/core/domain';

export const buildDivision = (input: IDivisionResponse): Division => {
  const result = new Division();
  result.id = input.id;
  result.name = input.name;
  result.address = input.address;
  result.type = input.type;
  result.subDivisions = input.subDivisions
    ? input.subDivisions.map((subDivision) => buildSubDivision(subDivision))
    : [];
  return result;
};

export const buildSubDivision = (input: ISubDivisionResponse): SubDivision => {
  const result = new SubDivision();
  result.id = input.id;
  result.name = input.name;
  result.type = input.type!;
  result.active = input.active!;
  return result;
};
