import { TimeTracking, TimeTrackingRepo } from '@app/core/domain';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TimeTrackingService {
  constructor(private timeTrackingRepo: TimeTrackingRepo) {}

  public getCurrentStatus(): Observable<TimeTracking> {
    return this.timeTrackingRepo.getCurrentStatus();
  }
}
