import { Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISelectOption } from '@app/ui-kit/shared/components/controls/select/select-option.interface';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { HasControl } from '@app/ui-kit/shared/models/has-control';
import { ErrorComponent } from '@app/ui-kit/shared/components/indicators/error/error.component';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    TranslateModule,
    PipesModule,
    ErrorComponent,
    FormsModule,
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<T> extends HasControl implements ControlValueAccessor, OnInit {
  @Input({ required: true }) public options: ISelectOption<T>[] | null | undefined;
  @Input({ required: false }) public placeholder = '';
  @Input({ required: false }) public enableSearch = false;
  @Input({ required: false }) public readonly = false;
  @Input({ required: false }) public disableClear = false;
  @Input({ required: false }) public optionsMaxHeight = 150;
  @ViewChild('searchFieldComponent') public searchField: ElementRef;

  public isOpen = false;
  public isSearchInputActive = false;
  public searchText: string | undefined = '';

  private _value?: ISelectOption<T> | undefined | null;
  public set value(value: ISelectOption<T> | undefined | null) {
    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }
  public get value(): ISelectOption<T> | undefined | null {
    return this._value;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    this.setComponentControl();
  }

  public onChange: (val: ISelectOption<T> | undefined | null) => undefined = () => undefined;
  public onTouch: (val: ISelectOption<T> | undefined | null) => undefined = () => undefined;

  public registerOnChange(fn: (val: ISelectOption<T> | undefined | null) => undefined): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => undefined): void {
    this.onTouch = fn;
  }

  public writeValue(value: ISelectOption<T> | null): void {
    this.value = value;
    this.isOpen = false;
    if (this.enableSearch) {
      this.searchText = this.value ? this.value?.name : '';
    }
  }

  public showOptions(): void {
    if (this.readonly) {
      return;
    }
    if (this.isOpen && !this.enableSearch) {
      this.isOpen = false;
      return;
    }
    this.isOpen = true;
    if (this.enableSearch) {
      this.isSearchInputActive = true;
      asyncScheduler.schedule(() => this.searchField?.nativeElement.focus(), 0);
    }
  }

  public hideOptions(): void {
    asyncScheduler.schedule(() => {
      this.isOpen = false;
      this.isSearchInputActive = false;
    }, 0);
  }

  public clear(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.writeValue(null);
  }
}
