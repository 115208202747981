import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { Position, PositionRepo } from '@app/core/domain';

@Injectable({ providedIn: 'root' })
export class PositionService {
  constructor(private positionRepo: PositionRepo) {}

  public getAll(): Observable<Position[]> {
    return this.positionRepo.getAll().pipe(
      catchError(() => {
        return of([]);
      })
    );
  }
}
