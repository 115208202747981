import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { AppLanguage, LanguageService } from '@app/core/services';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonComponent } from '@app/ui-kit/shared/components/controls/radio/radio-button.component';
import { MobileDialogComponent } from '@app/ui-kit/shared/components/dialogs/mobile-dialog/mobile-dialog.component';

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, RadioButtonComponent, ReactiveFormsModule, MobileDialogComponent],
})
export class LanguageDialogComponent {
  private readonly LANGUAGES_MAP = { uk: 'Ukrainian', ru: 'Russian' };
  public languages = Object.values(AppLanguage).map((lang) => {
    return { name: this.LANGUAGES_MAP[lang], value: lang };
  });
  public languageControl = new FormControl(this.languageService.getCurrentLanguage());

  constructor(private dialogRef: DialogRef, private languageService: LanguageService) {}

  public confirm(): void {
    if (this.languageControl.value) {
      this.languageService.switchLanguage(this.languageControl.value);
    }
    this.dialogRef.close();
  }
}
