import { Component, DestroyRef, inject } from '@angular/core';
import { AppLanguage, LanguageService, TimeTrackingWatcherService, PwaDialogService } from '@app/core/services';
import { Settings } from 'luxon';
import { registerLocaleData } from '@angular/common';
import ruLocale from '@angular/common/locales/ru';
import ukLocale from '@angular/common/locales/uk';
import { LanguageUtils } from '@app/ui-kit/shared/utils/language.utils';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private destroyRef = inject(DestroyRef);

  constructor(
    private languageService: LanguageService,
    private pwaDialogService: PwaDialogService,
    private timeTrackingWatcher: TimeTrackingWatcherService,
    private translate: TranslateService
  ) {
    this.languageService.setDefaultLanguage(AppLanguage.Ukrainian);
    registerLocaleData(ruLocale);
    registerLocaleData(ukLocale);
    this.languageService.currentLanguage$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lang) => {
      Settings.defaultLocale = LanguageUtils.getLocale(lang);
    });
    // wait for translations to be initialized
    this.translate
      .get('loaded')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.timeTrackingWatcher.init();
        this.pwaDialogService.showPwaInstallDialog();
        this.pwaDialogService.showPwaUpdateDialog();
      });
  }
}
