import { IDivisionResponse, IPositionResponse, ISubDivisionResponse } from '@app/core/api';

export interface IUserResponse {
  id: number;
  phoneNumber: string;
  image?: IImageUserResponse;
  name: string;
  surname: string;
  patronymic: string;
  additionalPhoneNumber: string;
  address: string;
  position?: IPositionResponse;
  topDivision?: IDivisionResponse;
  subDivision?: ISubDivisionResponse;
  traceable?: boolean;
}

export interface IImageUserResponse {
  uuid: string;
  name: string;
  size: number;
  path: string;
  contentType: string;
}
