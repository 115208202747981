import { Injectable } from '@angular/core';
import { IMenuData } from '@app/core/models/interfaces/menu-data.interface';

@Injectable({ providedIn: 'root' })
export class MenuStorageService {
  private readonly STORAGE_KEY = 'menu';
  private _menuData: IMenuData;

  public get menuData(): IMenuData {
    return this._menuData;
  }

  private set menuData(menuData: IMenuData) {
    this._menuData = menuData;
  }

  constructor() {
    this.getMenuDataFromStorage();
  }

  public setOpenedMenuItems(menuItems: string[]): void {
    this.menuData = { ...this.menuData, openedMenuItems: menuItems };
    this.updateMenuDataInLocalStorage();
  }

  public setIsMenuCollapsed(isCollapsed: boolean): void {
    this.menuData = { ...this.menuData, isCollapsed };
    this.updateMenuDataInLocalStorage();
  }

  private getMenuDataFromStorage(): void {
    const storageData = window.localStorage.getItem(this.STORAGE_KEY);
    if (storageData) {
      try {
        this.menuData = JSON.parse(storageData) as IMenuData;
      } catch {
        this.menuData = this.getDefaultMenuData();
      }
    } else {
      this.menuData = this.getDefaultMenuData();
    }
  }

  private getDefaultMenuData(): IMenuData {
    return { isCollapsed: false, openedMenuItems: [] };
  }

  private updateMenuDataInLocalStorage(): void {
    window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.menuData));
  }
}
