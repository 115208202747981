import { Injectable } from '@angular/core';
import { TimeTrackingService } from '@app/core/domain';
import { BehaviorSubject, of, switchMap, tap, timer } from 'rxjs';
import { DeviceUtils } from '@app/ui-kit/shared/utils/device.utils';
import { Router } from '@angular/router';
import { AuthenticationService, NotificationService } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class TimeTrackingWatcherService {
  private lastStatus = false;
  private status = new BehaviorSubject<boolean>(false);
  private location = new BehaviorSubject<string | null | undefined>(null);
  public status$ = this.status.asObservable();
  public location$ = this.location.asObservable();
  constructor(
    private trackingService: TimeTrackingService,
    private notification: NotificationService,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  public init(): void {
    if (!DeviceUtils.isMobileDevice()) {
      return;
    }
    this.authService.isAuthenticated$
      .pipe(
        switchMap((isAuthenticated) => {
          return isAuthenticated
            ? timer(0, 7 * 60 * 1000).pipe(
                switchMap(() => this.trackingService.getCurrentStatus()),
                tap((tracking) => {
                  if (!tracking.trackingRequired) {
                    this.status.next(tracking.isStatusOk());
                    return;
                  }
                  if (tracking.isStatusOk()) {
                    if (!this.lastStatus) {
                      this.lastStatus = true;
                      this.notification.success('successTracking');
                    }
                  } else {
                    const errorMessage = tracking.getErrorMessage();
                    if (errorMessage) {
                      this.notification.error(errorMessage, false);
                    }
                    this.lastStatus = false;
                  }
                  this.status.next(tracking.isStatusOk());
                  this.location.next(tracking.tracingLocationTitle);
                })
              )
            : of(null);
        })
      )
      .subscribe();
  }
}
