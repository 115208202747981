import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { Icon } from '@app/ui-kit/shared/components/indicators/icon/icons';

@Component({
  selector: 'app-action-button',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {
  @Input({ required: true }) public label = '';
  @Input({ required: false }) public iconName: Icon;
  @Input({ required: false }) public mode: 'default' | 'light' = 'default';
  @Input({ required: false }) public disabled = false;
}
