<div class="mobile-dialog">
  <div class="container">
    <div class="mobile-dialog__status-bar"
         (click)="onCloseDialog()"
         (touchstart)="swipe($event, 'start')"
         (touchmove)="swipe($event, 'move')"
         (touchend)="swipe($event, 'end')">
      <div class="mobile-dialog__status"></div>
    </div>
    <div class="mobile-dialog__header">{{header}}</div>
    <div class="data-container" [style.max-height]="'calc(' + containerHeight + 'px - 184px)'">
      <ng-content></ng-content>
    </div>
    <div class="footer" *ngIf="showFooter" [class.footer_fixed]="fixedFooter">
      <button class="button" (click)="onConfirm()">{{confirmButtonText | translate}}</button>
    </div>
  </div>
</div>
