import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() public set isFullscreen(value: boolean) {
    this.fullscreenClass = value;
  }
  @Input({ required: false }) backgroundColor = '#EEFBFF';
  @HostBinding('class.fullscreen') fullscreenClass = true;
}
