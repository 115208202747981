import { DeviceUtils } from '@app/ui-kit/shared/utils/device.utils';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const isMobileGuard = (): boolean => {
  const router = inject(Router);
  if (!DeviceUtils.isMobileDevice()) {
    router.navigate(['/']);
    return false;
  }
  return true;
};
