import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { IconComponent } from '@app/ui-kit/shared/components/indicators';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-movable-inline-dialog',
  standalone: true,
  imports: [CommonModule, CdkDrag, CdkDragHandle, IconComponent, TranslateModule],
  templateUrl: './movable-inline-dialog.component.html',
  styleUrls: ['./movable-inline-dialog.component.scss'],
})
export class MovableInlineDialogComponent {
  @Input({ required: false }) public width = 282;
  @Input({ required: false }) public header = '';

  constructor(private dialogRef: DialogRef) {}

  public close(): void {
    this.dialogRef.close();
  }
}
