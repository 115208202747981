import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Division, DivisionRepo, SubDivision } from '@app/core/domain';
import { buildDivision, buildSubDivision, IDivisionResponse, ISubDivisionResponse } from '@app/core/api';

@Injectable({ providedIn: 'root' })
export class DivisionRepoImpl implements DivisionRepo {
  private readonly BASE_API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public getAll(): Observable<Division[]> {
    return this.http
      .get<IDivisionResponse[]>(`${this.BASE_API_URL}/hr/top-divisions`)
      .pipe(map((data) => data.map(buildDivision)));
  }

  public getSubDivisions(topDivId: number): Observable<SubDivision[]> {
    return this.http
      .get<ISubDivisionResponse[]>(`${this.BASE_API_URL}/hr/top-divisions/${topDivId}/sub-divisions`)
      .pipe(map((data) => data.map(buildSubDivision)));
  }
}
