<div class="additional-header additional-header_collapsible" [class.additional-header_collapsed]="isCollapsed">
  <app-column-collapse [isCollapse]="isCollapsed" (click)="switch()"></app-column-collapse>
  <ng-container *ngIf="!isCollapsed">
    <span>
      <ng-container *ngIf="!(config.name | isArray); else names">{{ $any(config.name) | translate }}</ng-container>
      <ng-template #names>
        <ng-container *ngFor="let name of $any(config.name)">{{ name | translate }}</ng-container>
      </ng-template>
    </span>
    <span *ngIf="config.suffix" class="additional-header__suffix">
      <ng-container *ngIf="!(config.suffix | isArray); else suffixes">{{ $any(config.suffix) | translate }}</ng-container>
      <ng-template #suffixes>
        <ng-container *ngFor="let suffix of $any(config.suffix)">{{ suffix | translate }}</ng-container>
      </ng-template>
    </span>
  </ng-container>
</div>
