import { Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { ErrorComponent } from '@app/ui-kit/shared/components/indicators/error/error.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgxMaskDirective, PipesModule, IconComponent, TranslateModule, ErrorComponent],
  providers: [provideNgxMask(), NgxMaskPipe],
})
export class InputComponent implements ControlValueAccessor {
  @Input() public type: 'text' | 'textarea' | 'textHidden' | 'password' = 'text';
  @Input() public placeholder = '';
  @Input() public prefix = '';
  @Input() public mask = '';
  @Input() public error = '';
  @Input() public autocomplete: 'on' | 'off' = 'on';
  @Input({ required: false }) public readonly = false;
  @ViewChild('input') public input: ElementRef;

  public hidden = true;

  private _value = '';
  public set value(value: string) {
    this._value = this.getTransformedValue(value);
    this.onChange(this.value);
  }

  public get value(): string {
    return this._value;
  }

  constructor(@Self() @Optional() public control: NgControl, private maskPipe: NgxMaskPipe) {
    this.control.valueAccessor = this;
  }

  public onChange: (val: string) => undefined = () => undefined;
  public onTouch: (val: string) => undefined = () => undefined;

  public writeValue(value: string): void {
    this.value = value;
    this.control.control?.markAsTouched();
  }

  public registerOnChange(fn: (val: string) => undefined): void {
    this.onChange = fn;
  }

  public registerOnTouched(onTouched: (val: string) => undefined): void {
    this.onTouch = onTouched;
  }

  public onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.onChange(this.getTransformedValue(value));
  }

  public onBlur(): void {
    if (!this.control.touched) {
      this.control.control?.markAsTouched();
    }
  }

  public switchShowingPassword(): void {
    this.hidden = !this.hidden;
  }

  private getTransformedValue(value: string): string {
    return this.mask && value ? this.maskPipe.transform(value, this.mask, { prefix: this.prefix }) : value;
  }
}
