<div class="input-container" [class.input-container_without-icon]="!icon" [class.readonly]="readonly">
  <app-icon class="input-container__icon" *ngIf="icon" [name]="icon"></app-icon>
  <input
    #input
    class="input-container__input"
    type="text"
    (blur)="onBlur()"
    [readOnly]="readonly"
    [class.invalid]="control?.touched && control?.dirty && (control?.errors | notEmptyObject)"
    [mask]="mask"
    [suffix]="suffix ?? ''"
    [dropSpecialCharacters]="false"
    [(ngModel)]="value"
    (keyup.enter)="input.blur()"
    [placeholder]="placeholder" />
  <app-icon *ngIf="value" class="input-container__clear" name="close" (click)="clear()"></app-icon>
</div>

<div
  class="errors"
  *ngIf="showErrors && control && control.touched && control.dirty && (control.errors | notEmptyObject)">
  <app-error *ngIf="control.errors?.['required']" [error]="'fieldCannotBeEmpty' | translate"></app-error>
  <app-error *ngIf="control.errors?.['min']"
             [error]="'valueShouldBeNotLess' | translate:{param: control.errors?.['min']?.min}"></app-error>
  <app-error *ngIf="control.errors?.['max']"
             [error]="'valueShouldBeNotMore' | translate:{param: control.errors?.['max']?.max}"></app-error>
</div>
