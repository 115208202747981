import { DestroyRef, inject, Injectable } from '@angular/core';
import { DeviceUtils } from '@app/ui-kit/shared/utils/device.utils';
import { combineLatest, filter, first, fromEvent } from 'rxjs';
import { BeforeInstallPromptEvent } from '@app/core/models/interfaces/before-install-prompt-event.interface';
import { InstallPwaDialogComponent } from '@app/core/components/install-pwa-dialog/install-pwa-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UpdatePwaDialogComponent } from '@app/core/components/update-pwa-dialog/update-pwa-dialog.component';

@Injectable({ providedIn: 'root' })
export class PwaDialogService {
  private destroyRef = inject(DestroyRef);

  constructor(private dialog: Dialog, private overlay: Overlay, private router: Router, private swUpdate: SwUpdate) {}

  public showPwaInstallDialog(): void {
    if (DeviceUtils.isAndroidMobileDevice) {
      this.showAndroidDialog();
    } else if (DeviceUtils.isAppleMobileDevice && !this.isInStandaloneMode()) {
      this.showIosDialog();
    }
  }

  public showPwaUpdateDialog(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe({
        next: (versionEvent) => {
          if (versionEvent.type === 'VERSION_READY') {
            this.dialog.open(UpdatePwaDialogComponent, {
              backdropClass: 'cdk-overlay-transparent-backdrop',
              positionStrategy: this.overlay.position().global().bottom('40px').centerHorizontally(),
              disableClose: true,
            });
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private showAndroidDialog(): void {
    combineLatest([
      fromEvent(window, 'beforeinstallprompt'),
      this.router.events.pipe(
        filter((val) => {
          return val instanceof NavigationEnd && !val.url.startsWith('/auth');
        })
      ),
    ])
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([event]) => {
          event.preventDefault();
          const promptEvent = event as BeforeInstallPromptEvent;

          this.dialog.open(InstallPwaDialogComponent, {
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.overlay.position().global().bottom('40px'),
            data: { promptEvent },
            disableClose: true,
          });
        },
      });
  }

  private showIosDialog(): void {
    this.router.events
      .pipe(
        filter((val) => {
          return val instanceof NavigationEnd && !val.url.startsWith('/auth');
        }),
        first(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          this.dialog.open(InstallPwaDialogComponent, {
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.overlay.position().global().bottom('40px'),
            data: {},
            disableClose: true,
          });
        },
      });
  }

  private isInStandaloneMode = (): boolean => {
    return 'standalone' in window.navigator && !!window['navigator']['standalone'];
  };
}
