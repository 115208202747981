import { DeviceRepo } from '@app/core/domain';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IDeviceTokenResponse } from '@app/core/api';
import { Injectable } from '@angular/core';

@Injectable()
export class DeviceRepoImpl implements DeviceRepo {
  private readonly BASE_API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public getDeviceToken(): Observable<string> {
    return this.http
      .post<IDeviceTokenResponse>(`${this.BASE_API_URL}/device/create/token`, {})
      .pipe(map((data) => data.token));
  }
}
