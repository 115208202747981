import { Component, Input, OnChanges } from '@angular/core';
import { IconComponent, StoreInfoComponent } from '@app/ui-kit/shared/components/indicators';
import { Division } from '@app/core/domain';
import { DatePipe, NgIf } from '@angular/common';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-table-layout-header',
  standalone: true,
  templateUrl: './table-layout-header.component.html',
  styleUrls: ['./table-layout-header.component.scss'],
  imports: [StoreInfoComponent, NgIf, IconComponent, DatePipe],
})
export class TableLayoutHeaderComponent implements OnChanges {
  @Input({ required: false }) division: Division | null;
  @Input({ required: false }) date: DateTime | null;
  @Input({ required: false }) year: DateTime | null;
  public dateString: string | undefined;

  public ngOnChanges(): void {
    if (this.date) {
      this.dateString = this.date?.toFormat('LLLL yyyy');
    } else if (this.year) {
      this.dateString = this.year.toFormat('yyyy');
    }
  }
}
