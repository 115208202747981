import { Component, ContentChild, DestroyRef, Inject, inject, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButtonComponent } from '@app/ui-kit/shared/components/controls/action-button/action-button.component';
import { ActionInputComponent } from '@app/ui-kit/shared/components/controls/action-input/action-input.component';
import { DatePickerComponent } from '@app/ui-kit/shared/components/controls/date-picker/date-picker.component';
import { DynamicTableComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/dynamic-table.component';
import { LoaderComponent } from '@app/ui-kit/shared/components/indicators/loader/loader.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { asyncScheduler, Observable, shareReplay, tap } from 'rxjs';
import { IEditableHistoryService } from '@app/ui-kit/shared/interfaces/editable-history-service.interface';
import { DialogRef } from '@angular/cdk/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEditableHistoryConfig } from '@app/ui-kit/shared/interfaces/editable-history-config.interface';
import { EDITABLE_HISTORY_DATA_SERVICE_TOKEN } from '@app/ui-kit/shared/infrastracture/injection-tokens';
import { ITableRowConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/table-row-config';
import { DateUtils } from '@app/ui-kit/shared/utils/date.utils';
import { MovableInlineDialogComponent } from '@app/ui-kit/shared/components/dialogs/movable-inline-dialog/movable-inline-dialog.component';

@Component({
  selector: 'app-editable-history-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ActionButtonComponent,
    ActionInputComponent,
    DatePickerComponent,
    DynamicTableComponent,
    LoaderComponent,
    ReactiveFormsModule,
    TranslateModule,
    MovableInlineDialogComponent,
  ],
  templateUrl: './editable-history-dialog.component.html',
  styleUrls: ['./editable-history-dialog.component.scss'],
})
export class EditableHistoryDialogComponent<H extends { id: number; startDate?: string }> implements OnInit {
  @Input({ required: true }) public config: IEditableHistoryConfig<H>;
  @ContentChild('formTemplate') public formTemplate!: never;
  @ViewChild(DynamicTableComponent) dynamicTable: DynamicTableComponent<H>;
  public history$: Observable<H[]>;
  public historyRowsConfig: ITableRowConfig;
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(EDITABLE_HISTORY_DATA_SERVICE_TOKEN) private dataService: IEditableHistoryService<H>,
    private dialogRef: DialogRef
  ) {}

  public get formGroup(): FormGroup {
    return this.config.formGroup;
  }

  public ngOnInit(): void {
    this.history$ = this.dataService.getHistory(...this.config.historyParams).pipe(
      tap((history) => {
        this.setTableHistoryRowsConfig(history);
        asyncScheduler.schedule(() => {
          this.dialogRef.updatePosition();
        });
      }),
      takeUntilDestroyed(this.destroyRef),
      shareReplay(1)
    );
  }

  public onHistoryItemSelect(item: H | null): void {
    if (item) {
      this.formGroup.patchValue(this.config.onHistorySelect ? this.config.onHistorySelect(item) : item);
    } else {
      this.resetForm();
    }
  }

  public saveData(): void {
    this.formGroup.patchValue(this.formGroup.value);
    if (!this.formGroup.valid) {
      return;
    }
    const saveMethod = this.config.saveMethod?.bind(this.dataService) ?? this.dataService.save?.bind(this.dataService);
    saveMethod(this.config.formValueToSaveRequestMapper(this.formGroup))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          this.dynamicTable?.resetSelectedRow();
          this.resetForm();
          this.dialogRef.close();
        },
      });
  }

  public deleteData(): void {
    this.dataService
      .delete(this.formGroup.value.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.dynamicTable?.resetSelectedRow();
          this.resetForm();
          this.dialogRef.close();
        },
      });
  }

  public clear(): void {
    this.resetForm();
  }

  private setTableHistoryRowsConfig(history: H[]): void {
    if (this.config.getHistoryRows) {
      this.historyRowsConfig = this.config.getHistoryRows(history);
      return;
    }
    this.historyRowsConfig = history.reduce((prev: ITableRowConfig, next: H) => {
      prev[next.id] = {
        selectable: DateUtils.checkIfDateInFuture(next.startDate) || this.dataService.isHistoryEditable(next.startDate),
      };
      return prev;
    }, {});
  }

  private resetForm(): void {
    this.formGroup.reset();
    Object.keys(this.formGroup.controls).forEach((field) => {
      this.formGroup.get(field)?.setErrors(null);
    });
  }
}
