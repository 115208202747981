import { DynamicTableClass } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-class';

export interface IDynamicTableSubheaderConfig {
  name: string;
  sticky?: { left: number };
  cssClass?: DynamicTableClass | DynamicTableClass[];
  colspan?: number;
  hidden?: boolean;
  width?: string;
}
