import { TimeTracking, TimeTrackingRepo } from '@app/core/domain';
import { map, Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface TimeTrackingResponse {
  trackingOnline: boolean;
  trackingRequired: boolean;
  valid: boolean;
  lastTrackUpdateDate?: string;
  targetLocationId?: number;
  targetLocationTitle?: string;
  tracingLocationId?: number;
  tracingLocationTitle?: string;
}

const timeTrackingBuilder = (input: TimeTrackingResponse): TimeTracking => {
  const timeTracking = new TimeTracking();
  timeTracking.trackingOnline = input.trackingOnline;
  timeTracking.trackingRequired = input.trackingRequired;
  timeTracking.valid = input.valid;
  timeTracking.lastTrackUpdateDate = input.lastTrackUpdateDate;
  timeTracking.targetLocationId = input.targetLocationId;
  timeTracking.targetLocationTitle = input.targetLocationTitle;
  timeTracking.tracingLocationId = input.tracingLocationId;
  timeTracking.tracingLocationTitle = input.tracingLocationTitle;
  return timeTracking;
};

@Injectable({ providedIn: 'root' })
export class TimeTrackingRepoImpl implements TimeTrackingRepo {
  private readonly BASE_API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public getCurrentStatus(): Observable<TimeTracking> {
    return this.http.get<TimeTrackingResponse>(`${this.BASE_API_URL}/tracing/geo/`).pipe(map(timeTrackingBuilder));
  }
}
