import { Injectable } from '@angular/core';
import { Comment, CommentRepo, CommentType } from '@app/core/domain';
import { catchError, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentService {
  constructor(private commentRepo: CommentRepo) {}

  public getHistory(userId: number, type?: CommentType): Observable<Comment[]> {
    return this.commentRepo.getAll(userId, type).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  public edit(comment: Comment): Observable<Comment> {
    return this.commentRepo.edit(comment);
  }

  public confirm(comment: Comment): Observable<Comment> {
    return this.commentRepo.confirm(comment);
  }

  public delete(commentId: number): Observable<void> {
    return this.commentRepo.delete(commentId);
  }
}
