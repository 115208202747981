<div class="menu" [class.menu_minimal]="mode === 'minimal'" [class.menu_collapsed]="collapsed">
  <app-logo class="menu__logo" [size]="collapsed ? 'collapsed' : 'small'" *ngIf="mode === 'full'"></app-logo>

  <div class="menu__items">
    <ng-container *ngFor="let section of sections">
      <div class="menu__divider" *ngIf="mode === 'full'"></div>
      <ng-container *ngFor="let item of section">
        <a
          *ngIf="item.url; else menuItem"
          class="menu__item"
          [routerLink]="item.url"
          (click)="onMenuNavigation()"
          [title]="collapsed ? (item.name | translate) : ''"
          routerLinkActive="active">
          <app-icon class="menu__icon" [name]="item.icon"></app-icon>
          <span *ngIf="!collapsed">{{ item.name | translate }}</span>
        </a>
        <ng-template #menuItem>
          <a
            *ngIf="item.externalUrl || item.externalUrlOnClick"
            class="menu__item"
            [href]="item.externalUrl ?? '#'"
            [target]="item.openExternalUrlInNewTab ? '_blank' : ''"
            [title]="collapsed ? (item.name | translate) : ''"
            (click)="item.externalUrlOnClick && runExternalUrlOnClick($event, item.externalUrlOnClick)">
            <app-icon class="menu__icon" [name]="item.icon"></app-icon>
            <span *ngIf="!collapsed">{{ item.name | translate }}</span>
          </a>
          <ng-container *ngIf="item.menuItems">
            <a class="menu__item"
               [class.menu__opened-item]="item.name | displayFn: isOpened: openedMenuItems"
               [title]="collapsed ? (item.name | translate) : ''"
               (click)="switchMenuItem(item.name)">
              <app-icon name="arrow-back-new-rounded-down" class="menu__toggle-item"></app-icon>
              <span *ngIf="!collapsed">{{ item.name | translate }}</span>
            </a>
            <a class="menu__item menu__nested-item"
               [class.display-none]="!(item.name | displayFn: isOpened: openedMenuItems)"
               *ngFor="let nestedItem of item.menuItems"
               [routerLink]="nestedItem.url"
               [title]="collapsed ? (nestedItem.name | translate) : ''"
               (click)="onMenuNavigation()"
               routerLinkActive="active">
              <app-icon class="menu__icon" [name]="nestedItem.icon"></app-icon>
              <span *ngIf="!collapsed">{{ nestedItem.name | translate }}</span>
            </a>
          </ng-container>
        </ng-template>
      </ng-container>
      <div class="menu__divider" *ngIf="mode === 'minimal'"></div>
    </ng-container>
  </div>

  <div class="menu__footer">
    <div class="menu__toggle" *ngIf="isDesktop$ | async">
      <a class="menu__item" (click)="switchCollapsed()" [title]="collapsed ? ('unfold' | translate) : ''">
        <app-icon class="menu__icon" name="arrow-left-outline"></app-icon>
        <span *ngIf="!collapsed">{{'collapse' | translate}}</span>
      </a>
    </div>
    <div class="menu__logout">
      <div class="menu__divider" *ngIf="mode === 'full'"></div>
      <a class="menu__item" (click)="logout()" [title]="collapsed ? ('logout' | translate) : ''">
        <app-icon class="menu__icon" name="log-aut"></app-icon>
        <span *ngIf="!collapsed">{{ 'logout' | translate }}</span>
      </a>
    </div>
  </div>
</div>
