import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotEmptyObjectPipe } from './not-empty-object.pipe';
import { DisplayFnPipe } from './display-fn.pipe';
import { SearchFilterPipe } from './search-filter.pipe';

@NgModule({
  declarations: [NotEmptyObjectPipe, DisplayFnPipe, SearchFilterPipe],
  exports: [NotEmptyObjectPipe, DisplayFnPipe, SearchFilterPipe],
  imports: [CommonModule],
})
export class PipesModule {}
