<div class="store">
  <div class="store__name">
    <app-icon name="store"></app-icon>
    <strong>{{ store?.name }}</strong>
  </div>
  <div class="store__address" *ngIf="store?.address">
    <app-icon name="location-outline"></app-icon>
    <span>{{ store?.address }}</span>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
