import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceRepo } from '@app/core/domain';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  constructor(private deviceRepo: DeviceRepo) {}

  public getDeviceToken(): Observable<string> {
    return this.deviceRepo.getDeviceToken();
  }
}
