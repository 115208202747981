import { Component, Input } from '@angular/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';

@Component({
  selector: 'app-column-collapse',
  templateUrl: './column-collapse.component.html',
  styleUrls: ['./column-collapse.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class ColumnCollapseComponent {
  @Input({ required: false }) public isCollapse = false;
  @Input({ required: false }) isHorizontal = false;
}
