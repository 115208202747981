import { Division, Position, SubDivision } from '@app/core/domain';

export class UserShort {
  id: number;
  name: string;
  position?: Position;
}

export class User extends UserShort {
  surname: string;
  patronymic: string;
  phoneNumber: string;
  additionalPhoneNumber: string;
  address: string;
  traceable?: boolean;
  image?: Image;
  topDivision?: Division;
  subDivision?: SubDivision;
  access?: UserAccess[];
}

export class Image {
  uuid: string;
  name: string;
  size: number;
  path: string;
  contentType: string;
}

export class UserAccess {
  topDivision: Division;
  permissions: UserAccessPermission[];
}

export enum UserRole {
  USER = 'ROLE_USER',
  HR = 'ROLE_HR',
  SECURITY_INSPECTOR = 'ROLE_SECURITY_INSPECTOR',
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_TOP_DIVISION_MANAGER = 'ROLE_TOP_DIVISION_MANAGER',
  ROLE_SUB_DIVISION_MANAGER = 'ROLE_SUB_DIVISION_MANAGER',
  ROLE_SENIOR_SUSHI_MANAGER = 'ROLE_SENIOR_SUSHI_MASTER',
  ROLE_SUPER_SUSHI_MASTER = 'ROLE_SUPER_SUSHI_MASTER',
  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
}

export enum UserAccessPermission {
  PERMISSION_READ = 'PERMISSION_READ',
  PERMISSION_WRITE = 'PERMISSION_WRITE',
}
