import { DateTime, Interval } from 'luxon';
import { AuthenticationService } from '@app/core/services';
import { SystemUser } from '@app/core/models/classes/system-user';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MonthPeriodService {
  public static HALF_OF_MONTH = 15;
  public static EXTEND_BY_DAYS = 5;

  private get user(): SystemUser {
    return this.auth.getUser();
  }

  constructor(private auth: AuthenticationService) {}

  public getCurrentPeriod(): Interval {
    const today = DateTime.now();
    return today.day <= MonthPeriodService.HALF_OF_MONTH ? this.getFirstMonthPeriod() : this.getSecondMonthPeriod();
  }

  public isCurrentPeriodFirst(): boolean {
    return DateTime.now().day <= MonthPeriodService.HALF_OF_MONTH;
  }

  public isCurrentPeriodSecond(): boolean {
    return DateTime.now().day > MonthPeriodService.HALF_OF_MONTH;
  }

  public checkIfDateInCurrentPeriod(dateValue: string | DateTime | undefined): boolean {
    if (!dateValue) return false;
    const date = typeof dateValue === 'string' ? DateTime.fromSQL(dateValue) : dateValue;
    return this.getCurrentPeriod().contains(date);
  }

  public checkIfIntervalInCurrentPeriod(interval: Interval): boolean {
    const currentPeriod = this.getCurrentPeriod();
    return currentPeriod.contains(interval.start!) && currentPeriod.contains(interval.end!.minus({ day: 1 }));
  }

  private getFirstMonthPeriod(): Interval {
    const today = DateTime.now();
    const startOfMonth = today.startOf('month');
    // extend current period to current + previous if current date less or equal 'EXTENDED_BY_CONSTANT'
    if (this.user.canEditInExtendedPeriod() && today.day <= MonthPeriodService.EXTEND_BY_DAYS) {
      return Interval.fromDateTimes(
        startOfMonth.minus({ month: 1 }).plus({ day: MonthPeriodService.HALF_OF_MONTH }),
        startOfMonth.plus({ day: MonthPeriodService.HALF_OF_MONTH })
      );
    } else {
      return Interval.fromDateTimes(startOfMonth, startOfMonth.plus({ day: MonthPeriodService.HALF_OF_MONTH }));
    }
  }

  private getSecondMonthPeriod(): Interval {
    const today = DateTime.now();
    const startOfMonth = today.startOf('month');
    // extend current period to current + previous if current date less or equal 'EXTENDED_BY_CONSTANT'
    if (
      this.user.canEditInExtendedPeriod() &&
      today.day <= MonthPeriodService.HALF_OF_MONTH + MonthPeriodService.EXTEND_BY_DAYS
    ) {
      return Interval.fromDateTimes(startOfMonth, today.endOf('month').plus({ day: 1 }));
    } else {
      return Interval.fromDateTimes(
        startOfMonth.plus({ day: MonthPeriodService.HALF_OF_MONTH }),
        today.endOf('month').plus({ day: 1 })
      );
    }
  }
}
