<svg width="251" height="81" viewBox="0 0 251 81" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="size === 'default'">
  <g filter="url(#filter0_d_807_21152)">
    <path d="M22.4944 35.9138H2.1293V0.62823H22.2326V5.13054H7.5216V16.0198H20.5574V20.5222H7.5216V31.4115H22.4944V35.9138Z" fill="currentColor"/>
    <path d="M57.9198 33.0344C54.8834 35.2332 51.0791 36.3326 46.507 36.3326C40.9925 36.3326 36.5426 34.7097 33.1571 31.4638C29.8066 28.218 28.1313 23.8029 28.1313 18.2186C28.1313 12.8089 29.824 8.42874 33.2095 5.07819C36.6298 1.69273 40.9576 0 46.1929 0C50.2764 0 53.6967 0.785287 56.4539 2.35586V7.90522C53.4524 6.02053 50.1019 5.07819 46.4023 5.07819C42.6329 5.07819 39.579 6.28229 37.2406 8.6905C34.9371 11.0987 33.7853 14.2748 33.7853 18.2186C33.7853 22.2672 34.9196 25.4607 37.1883 27.7991C39.4918 30.1376 42.6504 31.3068 46.664 31.3068C48.7581 31.3068 50.7475 30.9228 52.6322 30.155V20.4175H45.4599V15.7581H57.9198V32.6679H57.8675L57.9198 33.0344Z" fill="currentColor"/>
    <path d="M86.0678 35.9138H65.7027V0.62823H85.8061V5.13054H71.095V16.0198H84.1308V20.5222H71.095V31.4115H86.0678V35.9138Z" fill="currentColor"/>
    <path d="M103.118 5.13054H98.5105V18.0092H103.379C105.613 18.0092 107.306 17.4334 108.457 16.2816C109.609 15.095 110.185 13.5244 110.185 11.5699C110.185 7.27699 107.829 5.13054 103.118 5.13054ZM98.5105 35.9138H93.1182V0.62823H102.96C107.009 0.62823 110.133 1.62293 112.332 3.61232C114.53 5.56681 115.63 8.23678 115.63 11.6222C115.595 14.1003 114.967 16.2118 113.745 17.9569C112.524 19.6671 110.831 20.9061 108.667 21.6739L117.881 35.9138H111.442L103.17 22.5115H98.5105V35.9138Z" fill="currentColor"/>
    <path d="M144.285 26.2809C144.285 29.3872 143.186 31.8303 140.987 33.6103C138.788 35.3902 135.944 36.2802 132.454 36.2802C128.58 36.2802 125.369 35.5299 122.821 34.0291V28.4274C125.578 30.4866 128.772 31.5162 132.401 31.5162C134.321 31.5162 135.839 31.0799 136.956 30.2074C138.073 29.2999 138.631 28.0609 138.631 26.4903C138.631 25.548 138.3 24.6929 137.637 23.9251C136.973 23.1223 136.258 22.529 135.49 22.1451C134.722 21.7263 133.571 21.1678 132.035 20.4698C131.127 20.051 130.482 19.7543 130.098 19.5798C129.749 19.4053 129.156 19.0912 128.318 18.6375C127.48 18.1837 126.852 17.7998 126.433 17.4857C126.049 17.1716 125.561 16.7353 124.967 16.1769C124.374 15.6185 123.938 15.0601 123.659 14.5016C123.414 13.9432 123.17 13.2975 122.926 12.5646C122.716 11.7968 122.611 10.9766 122.611 10.104C122.611 7.10248 123.693 4.72917 125.857 2.98409C128.056 1.23901 130.883 0.366469 134.338 0.366469C137.514 0.366469 140.219 0.924895 142.453 2.04175V7.38169C140.115 5.91583 137.462 5.18289 134.495 5.18289C132.576 5.18289 131.058 5.61916 129.941 6.4917C128.859 7.32934 128.318 8.44619 128.318 9.84226C128.318 11.2383 128.859 12.3552 129.941 13.1928C131.023 14.0305 132.681 14.9379 134.914 15.9151C136.38 16.5434 137.514 17.0843 138.317 17.5381C139.155 17.9918 140.097 18.6375 141.144 19.4751C142.226 20.2778 143.011 21.2551 143.5 22.4068C144.024 23.5237 144.285 24.8151 144.285 26.2809Z" fill="currentColor"/>
    <path d="M177.816 22.4068C177.816 26.979 176.63 30.4517 174.256 32.825C171.883 35.1983 168.602 36.3849 164.414 36.3849C160.296 36.3849 157.05 35.2332 154.677 32.9297C152.303 30.5913 151.117 27.2233 151.117 22.8257V0.62823H156.509V22.7733C156.509 25.7748 157.224 27.9737 158.655 29.3697C160.121 30.7309 162.041 31.4115 164.414 31.4115C169.754 31.4115 172.424 28.4448 172.424 22.5115V0.62823H177.816V22.4068Z" fill="currentColor"/>
    <path d="M191.289 35.9138H185.897V0.62823H192.284L200.974 15.3916C202.58 18.1488 204.028 20.6967 205.319 23.0351C206.646 25.3735 207.553 27.0139 208.042 27.9562L208.775 29.4221C208.565 26.246 208.46 21.5867 208.46 15.444V0.62823H213.853V35.9138H207.466L198.723 21.2027C197.152 18.5502 195.704 16.0547 194.378 13.7163C193.086 11.343 192.179 9.63285 191.655 8.5858L190.87 7.06758C191.149 10.5577 191.289 15.252 191.289 21.1504V35.9138Z" fill="currentColor"/>
    <path d="M233.102 35.9138H222.003V0.62823H232.997C238.233 0.62823 242.526 2.25116 245.876 5.49701C249.227 8.74286 250.902 12.8787 250.902 17.9045C250.902 23.1398 249.314 27.4501 246.138 30.8356C242.997 34.221 238.651 35.9138 233.102 35.9138ZM232.893 5.23524H227.5V31.3591H233.05C237.063 31.3591 240.117 30.1027 242.211 27.5897C244.306 25.0768 245.353 21.8484 245.353 17.9045C245.353 14.2748 244.218 11.2558 241.95 8.84756C239.681 6.43935 236.662 5.23524 232.893 5.23524Z" fill="currentColor"/>
    <path d="M58.3259 70.0053C58.3259 73.1116 57.2265 75.5547 55.0277 77.3347C52.8289 79.1146 49.9844 80.0046 46.4942 80.0046C42.6202 80.0046 39.4092 79.2542 36.8614 77.7535V72.1518C39.6186 74.211 42.8121 75.2406 46.4419 75.2406C48.3615 75.2406 49.8797 74.8043 50.9965 73.9318C52.1134 73.0243 52.6718 71.7853 52.6718 70.2147C52.6718 69.2724 52.3403 68.4173 51.6771 67.6495C51.014 66.8467 50.2985 66.2534 49.5307 65.8695C48.7628 65.4507 47.6111 64.8922 46.0754 64.1942C45.168 63.7754 44.5223 63.4787 44.1384 63.3042C43.7894 63.1297 43.196 62.8156 42.3584 62.3619C41.5208 61.9081 40.8925 61.5242 40.4737 61.2101C40.0898 60.896 39.6012 60.4597 39.0078 59.9013C38.4145 59.3429 37.9782 58.7845 37.699 58.226C37.4547 57.6676 37.2104 57.0219 36.9661 56.289C36.7567 55.5211 36.652 54.701 36.652 53.8284C36.652 50.8269 37.7339 48.4536 39.8978 46.7085C42.0966 44.9634 44.9237 44.0909 48.3789 44.0909C51.555 44.0909 54.2598 44.6493 56.4935 45.7661V51.1061C54.1551 49.6402 51.5026 48.9073 48.536 48.9073C46.6164 48.9073 45.0982 49.3436 43.9813 50.2161C42.8994 51.0537 42.3584 52.1706 42.3584 53.5667C42.3584 54.9627 42.8994 56.0796 43.9813 56.9172C45.0633 57.7549 46.7211 58.6623 48.9548 59.6395C50.4207 60.2678 51.555 60.8087 52.3577 61.2625C53.1953 61.7162 54.1377 62.3619 55.1847 63.1995C56.2667 64.0022 57.052 64.9795 57.5406 66.1312C58.0641 67.2481 58.3259 68.5395 58.3259 70.0053Z" fill="currentColor"/>
    <path d="M84.3704 79.6382H64.0053V44.3526H84.1086V48.8549H69.3976V59.7442H82.4334V64.2466H69.3976V75.1359H84.3704V79.6382Z" fill="currentColor"/>
    <path d="M98.5407 64.7177L97.9649 66.4977H109.273L108.697 64.7177C107.371 60.5993 106.289 57.1964 105.451 54.509C104.614 51.7867 104.108 50.0939 103.933 49.4308L103.671 48.4361C103.287 50.0765 101.577 55.5037 98.5407 64.7177ZM93.672 79.6382H87.9655L100.164 44.3526H107.231L119.272 79.6382H113.514L110.739 71H96.499L93.672 79.6382Z" fill="currentColor"/>
    <path d="M142.211 64.9271H129.384V79.6382H123.992V44.3526H143.991V48.8549H129.384V60.4248H142.211V64.9271Z" fill="currentColor"/>
    <path d="M175.776 48.6455C178.673 51.9263 180.121 56.3239 180.121 61.8383C180.121 67.3528 178.656 71.7679 175.724 75.0835C172.827 78.3992 168.9 80.057 163.944 80.057C159.023 80.057 155.079 78.3992 152.113 75.0835C149.181 71.7679 147.715 67.3528 147.715 61.8383C147.715 56.3588 149.181 51.9612 152.113 48.6455C155.045 45.3299 158.971 43.6721 163.892 43.6721C168.918 43.6721 172.879 45.3299 175.776 48.6455ZM156.144 52.2579C154.294 54.5963 153.369 57.7898 153.369 61.8383C153.369 65.8869 154.329 69.0979 156.249 71.4712C158.168 73.8445 160.734 75.0312 163.944 75.0312C167.26 75.0312 169.843 73.862 171.693 71.5235C173.542 69.1851 174.467 65.9567 174.467 61.8383C174.467 57.7549 173.525 54.5439 171.64 52.2055C169.79 49.8671 167.208 48.6979 163.892 48.6979C160.611 48.6979 158.029 49.8845 156.144 52.2579Z" fill="currentColor"/>
    <path d="M212.262 48.6455C215.159 51.9263 216.608 56.3239 216.608 61.8383C216.608 67.3528 215.142 71.7679 212.21 75.0835C209.313 78.3992 205.387 80.057 200.431 80.057C195.51 80.057 191.566 78.3992 188.599 75.0835C185.667 71.7679 184.202 67.3528 184.202 61.8383C184.202 56.3588 185.667 51.9612 188.599 48.6455C191.531 45.3299 195.457 43.6721 200.378 43.6721C205.404 43.6721 209.366 45.3299 212.262 48.6455ZM192.63 52.2579C190.781 54.5963 189.856 57.7898 189.856 61.8383C189.856 65.8869 190.815 69.0979 192.735 71.4712C194.655 73.8445 197.22 75.0312 200.431 75.0312C203.746 75.0312 206.329 73.862 208.179 71.5235C210.029 69.1851 210.954 65.9567 210.954 61.8383C210.954 57.7549 210.011 54.5439 208.127 52.2055C206.277 49.8671 203.694 48.6979 200.378 48.6979C197.098 48.6979 194.515 49.8845 192.63 52.2579Z" fill="currentColor"/>
    <path d="M233.2 79.6382H222.101V44.3526H233.095C238.331 44.3526 242.624 45.9756 245.974 49.2214C249.325 52.4673 251 56.6031 251 61.6289C251 66.8642 249.412 71.1745 246.236 74.56C243.095 77.9454 238.75 79.6382 233.2 79.6382ZM232.991 48.9596H227.598V75.0835H233.148C237.162 75.0835 240.215 73.8271 242.309 71.3141C244.404 68.8012 245.451 65.5728 245.451 61.6289C245.451 57.9992 244.316 54.9802 242.048 52.572C239.779 50.1638 236.76 48.9596 232.991 48.9596Z" fill="currentColor"/>
    <path d="M3.06742 68.3462H2.57562V69.721H3.09536C3.33381 69.721 3.51451 69.6595 3.63746 69.5366C3.7604 69.4099 3.82188 69.2422 3.82188 69.0336C3.82188 68.5753 3.57039 68.3462 3.06742 68.3462ZM2.57562 71.6323H2V67.8656H3.05065C3.48284 67.8656 3.81629 67.9718 4.05101 68.1841C4.28573 68.3928 4.40309 68.6778 4.40309 69.0392C4.39937 69.3037 4.3323 69.5291 4.2019 69.7154C4.0715 69.898 3.8908 70.0302 3.65981 70.1122L4.6434 71.6323H3.956L3.07301 70.2016H2.57562V71.6323Z" fill="currentColor"/>
    <path d="M8.0768 68.3239C8.38603 68.6741 8.54065 69.1435 8.54065 69.7322C8.54065 70.3208 8.38417 70.7921 8.07121 71.1461C7.76198 71.5 7.34283 71.677 6.81378 71.677C6.28845 71.677 5.86745 71.5 5.55076 71.1461C5.2378 70.7921 5.08132 70.3208 5.08132 69.7322C5.08132 69.1472 5.2378 68.6778 5.55076 68.3239C5.86372 67.9699 6.28286 67.7929 6.80819 67.7929C7.34469 67.7929 7.76756 67.9699 8.0768 68.3239ZM5.98108 68.7095C5.78362 68.9591 5.68489 69.3 5.68489 69.7322C5.68489 70.1644 5.78734 70.5071 5.99226 70.7605C6.19717 71.0138 6.47101 71.1405 6.81378 71.1405C7.16772 71.1405 7.44343 71.0157 7.64089 70.7661C7.83835 70.5164 7.93708 70.1718 7.93708 69.7322C7.93708 69.2963 7.83649 68.9535 7.6353 68.7039C7.43784 68.4543 7.16213 68.3294 6.80819 68.3294C6.45797 68.3294 6.18227 68.4561 5.98108 68.7095Z" fill="currentColor"/>
    <path d="M12.2902 71.3249C11.9661 71.5596 11.56 71.677 11.0719 71.677C10.4833 71.677 10.0082 71.5038 9.64684 71.1573C9.28917 70.8108 9.11034 70.3395 9.11034 69.7434C9.11034 69.1659 9.29104 68.6983 9.65243 68.3406C10.0176 67.9792 10.4795 67.7985 11.0384 67.7985C11.4743 67.7985 11.8394 67.8824 12.1338 68.05V68.6424C11.8133 68.4412 11.4557 68.3406 11.0608 68.3406C10.6584 68.3406 10.3324 68.4692 10.0828 68.7262C9.83685 68.9833 9.71391 69.3223 9.71391 69.7434C9.71391 70.1755 9.83499 70.5164 10.0772 70.7661C10.3231 71.0157 10.6602 71.1405 11.0887 71.1405C11.3122 71.1405 11.5246 71.0995 11.7258 71.0175V69.9781H10.9602V69.4807H12.2902V71.2858H12.2847L12.2902 71.3249Z" fill="currentColor"/>
    <path d="M14.0152 70.0396L13.9538 70.2296H15.1609L15.0994 70.0396C14.9578 69.5999 14.8423 69.2367 14.7529 68.9498C14.6635 68.6592 14.6095 68.4785 14.5909 68.4077L14.5629 68.3015C14.5219 68.4766 14.3394 69.056 14.0152 70.0396ZM13.4955 71.6323H12.8863L14.1885 67.8656H14.9429L16.2283 71.6323H15.6136L15.3174 70.7102H13.7973L13.4955 71.6323Z" fill="currentColor"/>
    <path d="M18.9844 71.6323H16.8663V67.8656H17.4419V71.1237H18.9844V71.6323Z" fill="currentColor"/>
    <path d="M20.5015 70.0396L20.44 70.2296H21.6471L21.5857 70.0396C21.4441 69.5999 21.3286 69.2367 21.2392 68.9498C21.1498 68.6592 21.0957 68.4785 21.0771 68.4077L21.0492 68.3015C21.0082 68.4766 20.8256 69.056 20.5015 70.0396ZM19.9817 71.6323H19.3726L20.6747 67.8656H21.4292L22.7146 71.6323H22.0998L21.8036 70.7102H20.2835L19.9817 71.6323Z" fill="currentColor"/>
    <path d="M23.9282 71.6323H23.3525V67.8656H24.0343L24.962 69.4416C25.1334 69.7359 25.288 70.0079 25.4259 70.2575C25.5675 70.5071 25.6643 70.6822 25.7165 70.7828L25.7947 70.9393C25.7724 70.6003 25.7612 70.1029 25.7612 69.4472V67.8656H26.3368V71.6323H25.655L24.7217 70.0619C24.5541 69.7787 24.3995 69.5124 24.2579 69.2627C24.12 69.0094 24.0232 68.8268 23.9673 68.7151L23.8835 68.553C23.9133 68.9256 23.9282 69.4267 23.9282 70.0563V71.6323Z" fill="currentColor"/>
    <path d="M28.3917 71.6323H27.2069V67.8656H28.3805C28.9394 67.8656 29.3976 68.0388 29.7553 68.3853C30.113 68.7318 30.2918 69.1733 30.2918 69.7098C30.2918 70.2687 30.1223 70.7288 29.7833 71.0902C29.4479 71.4516 28.9841 71.6323 28.3917 71.6323ZM28.3693 68.3574H27.7937V71.1461H28.3861C28.8146 71.1461 29.1406 71.012 29.3641 70.7437C29.5877 70.4755 29.6994 70.1308 29.6994 69.7098C29.6994 69.3223 29.5783 69.0001 29.3362 68.743C29.094 68.4859 28.7717 68.3574 28.3693 68.3574Z" fill="currentColor"/>
    <path d="M3.55146 78.8171H2.64502V72.8856H3.71867L5.17954 75.3673C5.44942 75.8308 5.6929 76.2591 5.90997 76.6522C6.13292 77.0452 6.28546 77.321 6.3676 77.4794L6.4908 77.7258C6.4556 77.1919 6.438 76.4087 6.438 75.3761V72.8856H7.34444V78.8171H6.27079L4.80112 76.3441C4.53711 75.8983 4.29363 75.4788 4.07069 75.0857C3.85361 74.6867 3.70107 74.3992 3.61307 74.2232L3.48106 73.968C3.528 74.5547 3.55146 75.3438 3.55146 76.3353V78.8171Z" fill="currentColor"/>
    <path d="M13.194 73.6072C13.6809 74.1587 13.9244 74.8979 13.9244 75.8249C13.9244 76.7519 13.678 77.4941 13.1852 78.0514C12.6982 78.6088 12.0382 78.8875 11.2051 78.8875C10.3778 78.8875 9.71487 78.6088 9.21618 78.0514C8.72336 77.4941 8.47695 76.7519 8.47695 75.8249C8.47695 74.9038 8.72336 74.1646 9.21618 73.6072C9.709 73.0499 10.369 72.7712 11.1963 72.7712C12.0411 72.7712 12.707 73.0499 13.194 73.6072ZM9.89381 74.2144C9.58286 74.6075 9.42739 75.1443 9.42739 75.8249C9.42739 76.5055 9.58873 77.0452 9.91141 77.4442C10.2341 77.8431 10.6653 78.0426 11.2051 78.0426C11.7624 78.0426 12.1966 77.8461 12.5075 77.453C12.8185 77.0599 12.974 76.5172 12.974 75.8249C12.974 75.1385 12.8155 74.5987 12.4987 74.2056C12.1878 73.8126 11.7536 73.616 11.1963 73.616C10.6448 73.616 10.2106 73.8155 9.89381 74.2144Z" fill="currentColor"/>
    <path d="M16.74 73.6424H15.9655V75.8073H16.784C17.1595 75.8073 17.444 75.7105 17.6376 75.5169C17.8312 75.3174 17.928 75.0534 17.928 74.7249C17.928 74.0032 17.532 73.6424 16.74 73.6424ZM15.9655 78.8171H15.0591V72.8856H16.7136C17.3941 72.8856 17.9192 73.0528 18.2889 73.3872C18.6585 73.7158 18.8433 74.1646 18.8433 74.7337C18.8374 75.1502 18.7318 75.5052 18.5265 75.7985C18.3211 76.086 18.0366 76.2943 17.6728 76.4233L19.2217 78.8171H18.1392L16.7488 76.5642H15.9655V78.8171Z" fill="currentColor"/>
    <path d="M24.9187 78.333C24.4083 78.7027 23.7688 78.8875 23.0002 78.8875C22.0733 78.8875 21.3252 78.6146 20.7561 78.069C20.1929 77.5234 19.9113 76.7812 19.9113 75.8425C19.9113 74.9331 20.1958 74.1968 20.7649 73.6336C21.3399 73.0645 22.0674 72.78 22.9474 72.78C23.6339 72.78 24.2088 72.912 24.6723 73.176V74.1088C24.1678 73.792 23.6045 73.6336 22.9826 73.6336C22.349 73.6336 21.8357 73.836 21.4426 74.2408C21.0553 74.6457 20.8617 75.1796 20.8617 75.8425C20.8617 76.5231 21.0524 77.0599 21.4338 77.453C21.821 77.8461 22.3519 78.0426 23.0266 78.0426C23.3787 78.0426 23.7131 77.9781 24.0299 77.849V76.2121H22.8242V75.4289H24.9187V78.2714H24.9099L24.9187 78.333Z" fill="currentColor"/>
    <path d="M29.6504 78.8171H26.227V72.8856H29.6064V73.6424H27.1335V75.4729H29.3248V76.2297H27.1335V78.0602H29.6504V78.8171Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00259 48.5191C5.70715 46.4016 8.48672 45.3428 10.3401 45.3428C12.1924 45.3428 14.7079 45.8722 17.883 46.9321C19.7353 47.4603 21.3246 47.7256 22.6475 47.7256C23.9704 47.7256 26.4847 46.7983 30.1904 44.946V52.0922C26.4847 50.2399 23.9704 49.3138 22.6475 49.3138C21.3235 49.3138 19.7353 49.5767 17.883 50.1073C14.972 51.166 12.4577 51.6954 10.3401 51.6954C8.2226 51.6954 5.44303 50.6367 2.00259 48.5191ZM2.00259 62.8114C5.70715 60.6939 8.48672 59.6351 10.3401 59.6351C12.1924 59.6351 14.7079 60.1645 17.883 61.2244C19.7353 61.7526 21.3246 62.0179 22.6475 62.0179C23.9704 62.0179 26.4847 61.0918 30.1904 59.2383V66.3845C26.4847 64.5322 23.9704 63.6061 22.6475 63.6061C21.3235 63.6061 19.7353 63.869 17.883 64.3996C14.972 65.4584 12.4577 65.9877 10.3401 65.9877C8.2226 65.9877 5.44303 64.929 2.00259 62.8114ZM30.1904 55.6653C26.4847 53.5477 23.7052 52.4889 21.8529 52.4889C20.0006 52.4889 17.4851 53.0183 14.31 54.0782C12.4577 54.6065 10.8684 54.8718 9.54549 54.8718C8.2226 54.8718 5.7083 53.9445 2.00259 52.0922V59.2383C5.70715 57.3861 8.2226 56.4599 9.54549 56.4599C10.8695 56.4599 12.4577 56.7229 14.31 57.2534C17.221 58.3122 19.7353 58.8416 21.8529 58.8416C23.9704 58.8416 26.75 57.7828 30.1904 55.6653Z" fill="currentColor"/>
  </g>
  <defs>
    <filter id="filter0_d_807_21152" x="0" y="0" width="253" height="84.0571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.32549 0 0 0 0 0.45098 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_807_21152"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_807_21152" result="shape"/>
    </filter>
  </defs>
</svg>


<svg width="96" height="32" viewBox="0 0 96 32" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="size === 'small'">
  <path d="M8.92716 14.3139H1.27418V1.05395H8.82879V2.74587H3.30054V6.83795H8.19924V8.52987H3.30054V12.622H8.92716V14.3139Z" fill="currentColor"/>
  <path d="M22.2396 13.2318C21.0986 14.0581 19.669 14.4713 17.9508 14.4713C15.8786 14.4713 14.2063 13.8614 12.9341 12.6416C11.675 11.4219 11.0454 9.76274 11.0454 7.66424C11.0454 5.63131 11.6815 3.9853 12.9538 2.7262C14.2391 1.45398 15.8654 0.817871 17.8328 0.817871C19.3673 0.817871 20.6526 1.11297 21.6888 1.70318V3.78857C20.5608 3.08032 19.3017 2.7262 17.9115 2.7262C16.495 2.7262 15.3474 3.17869 14.4686 4.08367C13.603 4.98865 13.1702 6.18217 13.1702 7.66424C13.1702 9.18565 13.5964 10.3857 14.4489 11.2645C15.3146 12.1432 16.5015 12.5826 18.0098 12.5826C18.7968 12.5826 19.5444 12.4383 20.2526 12.1498V8.49053H17.5574V6.73959H22.2396V13.0941H22.22L22.2396 13.2318Z" fill="currentColor"/>
  <path d="M32.8174 14.3139H25.1644V1.05395H32.719V2.74587H27.1907V6.83795H32.0894V8.52987H27.1907V12.622H32.8174V14.3139Z" fill="currentColor"/>
  <path d="M39.2245 2.74587H37.4932V7.58555H39.3228C40.1622 7.58555 40.7983 7.36914 41.2311 6.93632C41.664 6.49039 41.8804 5.90018 41.8804 5.16571C41.8804 3.55248 40.9951 2.74587 39.2245 2.74587ZM37.4932 14.3139H35.4668V1.05395H39.1654C40.6868 1.05395 41.8607 1.42775 42.687 2.17534C43.5133 2.90982 43.9264 3.91316 43.9264 5.18538C43.9133 6.11659 43.6772 6.91009 43.2182 7.56587C42.7591 8.20854 42.123 8.67414 41.3098 8.96269L44.7724 14.3139H42.3525L39.2441 9.27746H37.4932V14.3139Z" fill="currentColor"/>
  <path d="M54.6949 10.694C54.6949 11.8612 54.2817 12.7793 53.4554 13.4482C52.6292 14.1171 51.5602 14.4516 50.2487 14.4516C48.7928 14.4516 47.5862 14.1696 46.6287 13.6056V11.5006C47.6649 12.2744 48.865 12.6613 50.229 12.6613C50.9504 12.6613 51.5209 12.4974 51.9406 12.1695C52.3603 11.8285 52.5701 11.3629 52.5701 10.7726C52.5701 10.4185 52.4455 10.0972 52.1963 9.80865C51.9471 9.50699 51.6783 9.28402 51.3897 9.13975C51.1012 8.98236 50.6684 8.77251 50.0913 8.5102C49.7503 8.35281 49.5076 8.24133 49.3634 8.17575C49.2322 8.11017 49.0092 7.99213 48.6945 7.82163C48.3797 7.65112 48.1436 7.50685 47.9862 7.38881C47.8419 7.27077 47.6583 7.10682 47.4354 6.89697C47.2124 6.68712 47.0485 6.47727 46.9435 6.26742C46.8517 6.05757 46.7599 5.81493 46.6681 5.5395C46.5894 5.25096 46.5501 4.94274 46.5501 4.61485C46.5501 3.48691 46.9566 2.59504 47.7698 1.93926C48.5961 1.28348 49.6585 0.955586 50.9569 0.955586C52.1504 0.955586 53.1669 1.16544 54.0063 1.58514V3.59183C53.1276 3.04097 52.1308 2.76555 51.0159 2.76555C50.2946 2.76555 49.724 2.92949 49.3043 3.25738C48.8978 3.57216 48.6945 3.99186 48.6945 4.51648C48.6945 5.04111 48.8978 5.46081 49.3043 5.77559C49.7109 6.09036 50.3339 6.43137 51.1733 6.79861C51.7242 7.03469 52.1504 7.23798 52.4521 7.40848C52.7669 7.57899 53.121 7.82163 53.5145 8.1364C53.921 8.43806 54.2162 8.8053 54.3998 9.23812C54.5965 9.65782 54.6949 10.1431 54.6949 10.694Z" fill="currentColor"/>
  <path d="M67.2954 9.23812C67.2954 10.9563 66.8495 12.2613 65.9576 13.1531C65.0658 14.045 63.8329 14.4909 62.259 14.4909C60.7114 14.4909 59.4916 14.0581 58.5997 13.1925C57.7079 12.3137 57.262 11.0481 57.262 9.3955V1.05395H59.2883V9.37583C59.2883 10.5038 59.5572 11.3301 60.0949 11.8547C60.6458 12.3662 61.3671 12.622 62.259 12.622C64.2657 12.622 65.2691 11.5071 65.2691 9.27746V1.05395H67.2954V9.23812Z" fill="currentColor"/>
  <path d="M72.3583 14.3139H70.3319V1.05395H72.7321L75.9979 6.60187C76.6012 7.63801 77.1455 8.59545 77.6308 9.4742C78.1291 10.3529 78.4702 10.9694 78.6538 11.3235L78.9292 11.8744C78.8505 10.6808 78.8112 8.9299 78.8112 6.62154V1.05395H80.8375V14.3139H78.4374L75.1519 8.78563C74.5617 7.78884 74.0174 6.85107 73.519 5.97232C73.0337 5.08046 72.6927 4.43779 72.496 4.04432L72.2009 3.47379C72.3058 4.78535 72.3583 6.54941 72.3583 8.76595V14.3139Z" fill="currentColor"/>
  <path d="M88.0712 14.3139H83.9004V1.05395H88.0319C89.9992 1.05395 91.6124 1.66383 92.8716 2.88359C94.1307 4.10334 94.7602 5.65755 94.7602 7.5462C94.7602 9.51355 94.1634 11.1333 92.9699 12.4055C91.7895 13.6778 90.1566 14.3139 88.0712 14.3139ZM87.9925 2.78522H85.9662V12.6023H88.0516C89.5598 12.6023 90.7075 12.1301 91.4944 11.1858C92.2813 10.2415 92.6748 9.02827 92.6748 7.5462C92.6748 6.18217 92.2486 5.04767 91.396 4.14269C90.5435 3.23771 89.409 2.78522 87.9925 2.78522Z" fill="currentColor"/>
  <path d="M22.3922 27.1251C22.3922 28.2924 21.9791 29.2105 21.1528 29.8794C20.3265 30.5483 19.2576 30.8827 17.946 30.8827C16.4902 30.8827 15.2836 30.6007 14.3261 30.0368V27.9317C15.3622 28.7055 16.5623 29.0924 17.9264 29.0924C18.6477 29.0924 19.2182 28.9285 19.6379 28.6006C20.0577 28.2596 20.2675 27.794 20.2675 27.2038C20.2675 26.8497 20.1429 26.5283 19.8937 26.2398C19.6445 25.9381 19.3756 25.7152 19.0871 25.5709C18.7985 25.4135 18.3657 25.2037 17.7886 24.9413C17.4476 24.784 17.205 24.6725 17.0607 24.6069C16.9296 24.5413 16.7066 24.4233 16.3918 24.2528C16.0771 24.0823 15.841 23.938 15.6836 23.82C15.5393 23.7019 15.3557 23.538 15.1327 23.3281C14.9098 23.1183 14.7458 22.9084 14.6409 22.6986C14.5491 22.4887 14.4573 22.2461 14.3655 21.9707C14.2868 21.6821 14.2474 21.3739 14.2474 21.046C14.2474 19.9181 14.654 19.0262 15.4672 18.3704C16.2935 17.7146 17.3558 17.3867 18.6543 17.3867C19.8478 17.3867 20.8643 17.5966 21.7037 18.0163V20.023C20.8249 19.4721 19.8281 19.1967 18.7133 19.1967C17.9919 19.1967 17.4214 19.3606 17.0017 19.6885C16.5951 20.0033 16.3918 20.423 16.3918 20.9476C16.3918 21.4723 16.5951 21.892 17.0017 22.2067C17.4083 22.5215 18.0313 22.8625 18.8707 23.2298C19.4215 23.4658 19.8478 23.6691 20.1495 23.8396C20.4642 24.0101 20.8184 24.2528 21.2118 24.5676C21.6184 24.8692 21.9135 25.2365 22.0971 25.6693C22.2939 26.089 22.3922 26.5742 22.3922 27.1251Z" fill="currentColor"/>
  <path d="M32.1795 30.745H24.5265V17.4851H32.0811V19.177H26.5529V23.2691H31.4516V24.961H26.5529V29.0531H32.1795V30.745Z" fill="currentColor"/>
  <path d="M37.5045 25.1381L37.2881 25.807H41.5376L41.3212 25.1381C40.8228 23.5904 40.4162 22.3117 40.1014 21.3018C39.7867 20.2787 39.5965 19.6426 39.5309 19.3934L39.4325 19.0196C39.2883 19.6361 38.6456 21.6756 37.5045 25.1381ZM35.6749 30.745H33.5305L38.1144 17.4851H40.7703L45.2952 30.745H43.1311L42.0885 27.4989H36.7373L35.6749 30.745Z" fill="currentColor"/>
  <path d="M53.9153 25.2168H49.0953V30.745H47.0689V17.4851H54.5842V19.177H49.0953V23.5249H53.9153V25.2168Z" fill="currentColor"/>
  <path d="M66.5287 19.0983C67.6173 20.3312 68.1616 21.9838 68.1616 24.056C68.1616 26.1283 67.6108 27.7874 66.5091 29.0334C65.4205 30.2794 63.945 30.9024 62.0825 30.9024C60.2332 30.9024 58.7512 30.2794 57.6363 29.0334C56.5346 27.7874 55.9838 26.1283 55.9838 24.056C55.9838 21.9969 56.5346 20.3443 57.6363 19.0983C58.738 17.8523 60.2136 17.2293 62.0629 17.2293C63.9515 17.2293 65.4401 17.8523 66.5287 19.0983ZM59.1512 20.4558C58.4561 21.3345 58.1085 22.5346 58.1085 24.056C58.1085 25.5775 58.4692 26.7841 59.1905 27.676C59.9119 28.5678 60.8759 29.0138 62.0825 29.0138C63.3285 29.0138 64.2991 28.5744 64.9942 27.6956C65.6893 26.8169 66.0369 25.6037 66.0369 24.056C66.0369 22.5215 65.6828 21.3149 64.9745 20.4361C64.2794 19.5574 63.3089 19.118 62.0629 19.118C60.83 19.118 59.8594 19.5639 59.1512 20.4558Z" fill="currentColor"/>
  <path d="M80.2399 19.0983C81.3285 20.3312 81.8728 21.9838 81.8728 24.056C81.8728 26.1283 81.322 27.7874 80.2203 29.0334C79.1317 30.2794 77.6561 30.9024 75.7937 30.9024C73.9444 30.9024 72.4623 30.2794 71.3475 29.0334C70.2458 27.7874 69.6949 26.1283 69.6949 24.056C69.6949 21.9969 70.2458 20.3443 71.3475 19.0983C72.4492 17.8523 73.9247 17.2293 75.774 17.2293C77.6627 17.2293 79.1513 17.8523 80.2399 19.0983ZM72.8624 20.4558C72.1672 21.3345 71.8197 22.5346 71.8197 24.056C71.8197 25.5775 72.1804 26.7841 72.9017 27.676C73.6231 28.5678 74.5871 29.0138 75.7937 29.0138C77.0397 29.0138 78.0103 28.5744 78.7054 27.6956C79.4005 26.8169 79.7481 25.6037 79.7481 24.056C79.7481 22.5215 79.394 21.3149 78.6857 20.4361C77.9906 19.5574 77.02 19.118 75.774 19.118C74.5412 19.118 73.5706 19.5639 72.8624 20.4558Z" fill="currentColor"/>
  <path d="M88.1081 30.745H83.9373V17.4851H88.0687C90.0361 17.4851 91.6493 18.095 92.9084 19.3147C94.1675 20.5345 94.7971 22.0887 94.7971 23.9773C94.7971 25.9447 94.2003 27.5645 93.0068 28.8367C91.8264 30.1089 90.1935 30.745 88.1081 30.745ZM88.0294 19.2164H86.003V29.0334H88.0884C89.5967 29.0334 90.7443 28.5613 91.5313 27.6169C92.3182 26.6726 92.7117 25.4594 92.7117 23.9773C92.7117 22.6133 92.2854 21.4788 91.4329 20.5738C90.5804 19.6689 89.4459 19.2164 88.0294 19.2164Z" fill="currentColor"/>
  <path d="M1.62671 26.5016H1.4419V27.0183H1.63721C1.72682 27.0183 1.79472 26.9952 1.84092 26.949C1.88713 26.9014 1.91023 26.8383 1.91023 26.7599C1.91023 26.5877 1.81572 26.5016 1.62671 26.5016ZM1.4419 27.7365H1.22559V26.321H1.62041C1.78282 26.321 1.90813 26.3609 1.99633 26.4407C2.08454 26.5191 2.12864 26.6262 2.12864 26.762C2.12724 26.8614 2.10204 26.9462 2.05304 27.0162C2.00403 27.0848 1.93613 27.1345 1.84932 27.1653L2.21895 27.7365H1.96063L1.62881 27.1989H1.4419V27.7365Z" fill="currentColor"/>
  <path d="M3.50918 26.4932C3.62539 26.6248 3.68349 26.8012 3.68349 27.0225C3.68349 27.2437 3.62469 27.4208 3.50708 27.5538C3.39087 27.6868 3.23336 27.7533 3.03455 27.7533C2.83714 27.7533 2.67893 27.6868 2.55992 27.5538C2.44232 27.4208 2.38351 27.2437 2.38351 27.0225C2.38351 26.8026 2.44232 26.6262 2.55992 26.4932C2.67753 26.3602 2.83504 26.2937 3.03245 26.2937C3.23406 26.2937 3.39297 26.3602 3.50918 26.4932ZM2.72163 26.6381C2.64743 26.7319 2.61033 26.86 2.61033 27.0225C2.61033 27.1849 2.64883 27.3137 2.72583 27.4089C2.80284 27.5041 2.90574 27.5517 3.03455 27.5517C3.16756 27.5517 3.27117 27.5048 3.34537 27.411C3.41957 27.3172 3.45668 27.1877 3.45668 27.0225C3.45668 26.8586 3.41887 26.7298 3.34327 26.636C3.26907 26.5422 3.16546 26.4953 3.03245 26.4953C2.90084 26.4953 2.79724 26.5429 2.72163 26.6381Z" fill="currentColor"/>
  <path d="M5.09255 27.621C4.97074 27.7092 4.81813 27.7533 4.63472 27.7533C4.4135 27.7533 4.23499 27.6882 4.09919 27.558C3.96478 27.4278 3.89757 27.2507 3.89757 27.0267C3.89757 26.8096 3.96548 26.6339 4.10129 26.4995C4.23849 26.3637 4.4121 26.2958 4.62212 26.2958C4.78593 26.2958 4.92314 26.3273 5.03374 26.3903V26.6129C4.91334 26.5373 4.77893 26.4995 4.63052 26.4995C4.47931 26.4995 4.3568 26.5478 4.263 26.6444C4.17059 26.741 4.12439 26.8684 4.12439 27.0267C4.12439 27.1891 4.16989 27.3172 4.2609 27.411C4.3533 27.5048 4.48001 27.5517 4.64102 27.5517C4.72502 27.5517 4.80483 27.5363 4.88043 27.5055V27.1149H4.59272V26.928H5.09255V27.6063H5.09045L5.09255 27.621Z" fill="currentColor"/>
  <path d="M5.74078 27.138L5.71768 27.2094H6.17131L6.14821 27.138C6.095 26.9728 6.0516 26.8362 6.018 26.7284C5.9844 26.6192 5.96409 26.5513 5.95709 26.5247L5.94659 26.4848C5.93119 26.5506 5.86259 26.7683 5.74078 27.138ZM5.54547 27.7365H5.31655L5.80588 26.321H6.0894L6.57243 27.7365H6.34142L6.23011 27.39H5.65888L5.54547 27.7365Z" fill="currentColor"/>
  <path d="M7.60812 27.7365H6.81217V26.321H7.02849V27.5454H7.60812V27.7365Z" fill="currentColor"/>
  <path d="M8.17824 27.138L8.15514 27.2094H8.60877L8.58567 27.138C8.53246 26.9728 8.48906 26.8362 8.45546 26.7284C8.42186 26.6192 8.40156 26.5513 8.39456 26.5247L8.38405 26.4848C8.36865 26.5506 8.30005 26.7683 8.17824 27.138ZM7.98293 27.7365H7.75402L8.24335 26.321H8.52686L9.00989 27.7365H8.77888L8.66757 27.39H8.09634L7.98293 27.7365Z" fill="currentColor"/>
  <path d="M9.46595 27.7365H9.24964V26.321H9.50585L9.85447 26.9132C9.91888 27.0239 9.97698 27.1261 10.0288 27.2199C10.082 27.3137 10.1184 27.3795 10.138 27.4173L10.1674 27.4761C10.159 27.3487 10.1548 27.1618 10.1548 26.9154V26.321H10.3711V27.7365H10.1149L9.76417 27.1464C9.70116 27.04 9.64306 26.9399 9.58986 26.846C9.53805 26.7508 9.50165 26.6822 9.48065 26.6402L9.44915 26.5793C9.46035 26.7193 9.46595 26.9077 9.46595 27.1443V27.7365Z" fill="currentColor"/>
  <path d="M11.1433 27.7365H10.6981V26.321H11.1391C11.3491 26.321 11.5213 26.3861 11.6557 26.5163C11.7901 26.6465 11.8573 26.8124 11.8573 27.0141C11.8573 27.2241 11.7936 27.397 11.6662 27.5328C11.5402 27.6686 11.3659 27.7365 11.1433 27.7365ZM11.1349 26.5058H10.9186V27.5538H11.1412C11.3022 27.5538 11.4247 27.5034 11.5087 27.4026C11.5927 27.3018 11.6347 27.1723 11.6347 27.0141C11.6347 26.8684 11.5892 26.7473 11.4982 26.6507C11.4072 26.5541 11.2861 26.5058 11.1349 26.5058Z" fill="currentColor"/>
  <path d="M1.80861 30.4365H1.46798V28.2075H1.87144L2.42042 29.1401C2.52184 29.3142 2.61334 29.4752 2.69491 29.6229C2.77869 29.7706 2.83601 29.8742 2.86688 29.9338L2.91318 30.0264C2.89995 29.8257 2.89334 29.5314 2.89334 29.1434V28.2075H3.23397V30.4365H2.8305L2.27822 29.5072C2.179 29.3396 2.08751 29.182 2.00373 29.0342C1.92215 28.8843 1.86483 28.7763 1.83176 28.7101L1.78215 28.6142C1.79979 28.8347 1.80861 29.1313 1.80861 29.5039V30.4365Z" fill="currentColor"/>
  <path d="M5.43216 28.4787C5.61515 28.6859 5.70665 28.9637 5.70665 29.312C5.70665 29.6604 5.61405 29.9393 5.42885 30.1487C5.24586 30.3582 4.99782 30.4629 4.68475 30.4629C4.37388 30.4629 4.12475 30.3582 3.93735 30.1487C3.75215 29.9393 3.65955 29.6604 3.65955 29.312C3.65955 28.9659 3.75215 28.6881 3.93735 28.4787C4.12254 28.2692 4.37058 28.1645 4.68144 28.1645C4.99893 28.1645 5.24916 28.2692 5.43216 28.4787ZM4.19199 28.7068C4.07514 28.8546 4.01672 29.0563 4.01672 29.312C4.01672 29.5678 4.07735 29.7706 4.19861 29.9205C4.31987 30.0705 4.48192 30.1454 4.68475 30.1454C4.8942 30.1454 5.05735 30.0716 5.1742 29.9239C5.29105 29.7761 5.34948 29.5722 5.34948 29.312C5.34948 29.0541 5.28995 28.8513 5.1709 28.7035C5.05404 28.5558 4.89089 28.482 4.68144 28.482C4.4742 28.482 4.31105 28.5569 4.19199 28.7068Z" fill="currentColor"/>
  <path d="M6.76471 28.4919H6.47369V29.3054H6.78125C6.92235 29.3054 7.02928 29.269 7.10203 29.1963C7.17479 29.1213 7.21117 29.0221 7.21117 28.8987C7.21117 28.6275 7.06235 28.4919 6.76471 28.4919ZM6.47369 30.4365H6.13305V28.2075H6.75479C7.01054 28.2075 7.20786 28.2703 7.34676 28.396C7.48566 28.5194 7.55511 28.6881 7.55511 28.902C7.5529 29.0585 7.51322 29.1919 7.43605 29.3021C7.35889 29.4102 7.25196 29.4884 7.11526 29.5369L7.69731 30.4365H7.29054L6.76802 29.5898H6.47369V30.4365Z" fill="currentColor"/>
  <path d="M9.83819 30.2546C9.64638 30.3935 9.40607 30.4629 9.11725 30.4629C8.7689 30.4629 8.48779 30.3604 8.27394 30.1554C8.06228 29.9503 7.95645 29.6714 7.95645 29.3187C7.95645 28.9769 8.06338 28.7002 8.27724 28.4886C8.49331 28.2747 8.76669 28.1678 9.0974 28.1678C9.35536 28.1678 9.57142 28.2174 9.74559 28.3166V28.6672C9.55599 28.5481 9.34433 28.4886 9.11063 28.4886C8.87252 28.4886 8.67961 28.5646 8.53189 28.7168C8.38638 28.8689 8.31362 29.0695 8.31362 29.3187C8.31362 29.5744 8.38527 29.7761 8.52858 29.9239C8.67409 30.0716 8.87362 30.1454 9.12717 30.1454C9.25945 30.1454 9.38512 30.1212 9.50418 30.0727V29.4576H9.0511V29.1632H9.83819V30.2314H9.83489L9.83819 30.2546Z" fill="currentColor"/>
  <path d="M11.6163 30.4365H10.3298V28.2075H11.5998V28.4919H10.6705V29.1798H11.4939V29.4642H10.6705V30.152H11.6163V30.4365Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22656 19.0508C2.61869 18.2551 3.66322 17.8572 4.35972 17.8572C5.05579 17.8572 6.00106 18.0561 7.19426 18.4544C7.89033 18.6529 8.48757 18.7526 8.9847 18.7526C9.48183 18.7526 10.4267 18.4042 11.8192 17.7081V20.3935C10.4267 19.6975 9.48183 19.3494 8.9847 19.3494C8.48714 19.3494 7.89033 19.4483 7.19426 19.6476C6.10032 20.0455 5.15547 20.2444 4.35972 20.2444C3.56397 20.2444 2.51944 19.8466 1.22656 19.0508ZM1.22656 24.4217C2.61869 23.626 3.66322 23.2281 4.35972 23.2281C5.05579 23.2281 6.00106 23.427 7.19426 23.8253C7.89033 24.0238 8.48757 24.1235 8.9847 24.1235C9.48183 24.1235 10.4267 23.7755 11.8192 23.079V25.7644C10.4267 25.0684 9.48183 24.7203 8.9847 24.7203C8.48714 24.7203 7.89033 24.8192 7.19426 25.0185C6.10032 25.4164 5.15547 25.6153 4.35972 25.6153C3.56397 25.6153 2.51944 25.2175 1.22656 24.4217ZM11.8192 21.7363C10.4267 20.9405 9.38214 20.5426 8.68608 20.5426C7.99001 20.5426 7.04473 20.7416 5.85154 21.1399C5.15547 21.3384 4.55823 21.4381 4.0611 21.4381C3.56397 21.4381 2.61912 21.0896 1.22656 20.3935V23.079C2.61869 22.3829 3.56397 22.0349 4.0611 22.0349C4.55866 22.0349 5.15547 22.1337 5.85154 22.3331C6.94548 22.731 7.89033 22.9299 8.68608 22.9299C9.48183 22.9299 10.5264 22.532 11.8192 21.7363Z" fill="currentColor"/>
</svg>

<svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="size === 'collapsed'">
  <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M0.46875 4.13958C4.02508 2.10676 6.69343 1.09035 8.47269 1.09035C10.2509 1.09035 12.6656 1.59856 15.7138 2.61606C17.4919 3.12317 19.0176 3.37782 20.2876 3.37782C21.5576 3.37782 23.9713 2.48764 27.5287 0.709473V7.56968C23.9713 5.79151 21.5576 4.90243 20.2876 4.90243C19.0165 4.90243 17.4919 5.15489 15.7138 5.66419C12.9192 6.6806 10.5055 7.1888 8.47269 7.1888C6.43987 7.1888 3.77153 6.17239 0.46875 4.13958ZM0.46875 17.86C4.02508 15.8272 6.69343 14.8108 8.47269 14.8108C10.2509 14.8108 12.6656 15.319 15.7138 16.3365C17.4919 16.8436 19.0176 17.0982 20.2876 17.0982C21.5576 17.0982 23.9713 16.2091 27.5287 14.4299V21.2901C23.9713 19.5119 21.5576 18.6228 20.2876 18.6228C19.0165 18.6228 17.4919 18.8753 15.7138 19.3846C12.9192 20.401 10.5055 20.9092 8.47269 20.9092C6.43987 20.9092 3.77153 19.8928 0.46875 17.86ZM27.5287 10.9998C23.9713 8.96697 21.3029 7.95056 19.5248 7.95056C17.7466 7.95056 15.3318 8.45876 12.2837 9.47627C10.5055 9.98337 8.97979 10.238 7.70983 10.238C6.43987 10.238 4.02618 9.34784 0.46875 7.56968V14.4299C4.02508 12.6517 6.43987 11.7626 7.70983 11.7626C8.98089 11.7626 10.5055 12.0151 12.2837 12.5244C15.0782 13.5408 17.4919 14.049 19.5248 14.049C21.5576 14.049 24.2259 13.0326 27.5287 10.9998Z" fill="white"/>
</svg>
