import { Pipe, PipeTransform } from '@angular/core';
import { ObjectUtils } from '@app/ui-kit/shared/utils/object.utils';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  public transform<T>(data: T, search: string | undefined, fieldsForSearch: string[]): T {
    if (!search || !fieldsForSearch?.length) {
      return data;
    }
    if (Array.isArray(data) && Array.isArray(data[0])) {
      return data.map((items) => {
        return this.filterItems(items, search, fieldsForSearch);
      }) as T;
    } else {
      return this.filterItems(data, search, fieldsForSearch);
    }
  }

  private filterItems<T>(items: T, search: string, fieldsForSearch: string[]): T {
    if (Array.isArray(items)) {
      return items.filter((item) => {
        return fieldsForSearch.some((field) => {
          return ObjectUtils.resolveFieldData(item, field)?.toString().toLowerCase().includes(search.toLowerCase());
        });
      }) as T;
    } else {
      return items;
    }
  }
}
