import { AppLanguage, LanguageService } from './language';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class LanguageServiceImpl extends LanguageService implements OnDestroy {
  private STORAGE_KEY = 'lang';
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {
    super();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setDefaultLanguage(language: AppLanguage): void {
    const languageFromStorage = this.getLanguageFromStorage();
    if (languageFromStorage) {
      this.translateService.setDefaultLang(languageFromStorage);
      this.translateService.use(languageFromStorage);
      this.currentLanguage = languageFromStorage as AppLanguage;
      return;
    }
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    this.currentLanguage = language;
  }

  public switchLanguage(language: AppLanguage): void {
    this.translateService
      .use(language)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.currentLanguage = language;
        this.setLanguageToStorage(language);
      });
  }

  public translate(text: string): string {
    return this.translateService.instant(text);
  }

  private setLanguageToStorage(language: AppLanguage): void {
    return window.localStorage.setItem(this.STORAGE_KEY, language);
  }

  private getLanguageFromStorage(): string | null {
    return window.localStorage.getItem(this.STORAGE_KEY);
  }
}
