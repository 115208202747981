<div class="dialog">
  <div class="dialog__message">
    <app-icon class="dialog__icon" name="question-mark-circled"></app-icon>
    {{(data.promptEvent ? 'addAppOnHomeScreen' : 'addAppOnHomeScreenApple') | translate}}
  </div>

  <div class="dialog__actions" *ngIf="data.promptEvent; else dialogAction">
    <button class="button button_disabled" (click)="close()">{{'remindLater' | translate}}</button>
    <button class="button" (click)="addApplicationToHomeScreen()">{{'add' | translate}}</button>
  </div>

  <ng-template #dialogAction>
    <div class="dialog__action">
      <button class="button" (click)="close()">{{'clear' | translate}}</button>
    </div>
  </ng-template>
</div>
