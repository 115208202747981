<app-inline-dialog [header]="data.title ?? '' | translate" [width]="(data.width ?? 0) + 'px'">
  <div class="container">
    <div class="message">
      <app-icon name="info-circle-fill"></app-icon>
      <p>{{data.text ?? '' | translate}}</p>
    </div>
    <div class="actions">
      <app-action-button [label]="data.cancelButtonText ?? '' | translate" mode="light" (click)="cancel()"></app-action-button>
      <app-action-button [label]="data.okButtonText ?? '' | translate" mode="light" (click)="confirm()"></app-action-button>
    </div>
  </div>
</app-inline-dialog>
