import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MainLayoutModule } from '@app/ui-kit/layouts/main/main-layout.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory } from '@app/core/factories/http-loader.factory';
import { LanguageService, Locale, LanguageServiceImpl } from '@app/core/services';
import { TokenInterceptor } from '@app/core/interceptors/token.interceptor';
import { ErrorInterceptor } from '@app/core/interceptors/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationComponent } from '@app/ui-kit/shared/components/indicators/notification/notification.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommentRepo, DeviceRepo, DivisionRepo, PositionRepo, TimeTrackingRepo, UserRepo } from '@app/core/domain';
import {
  TimeTrackingRepoImpl,
  DeviceRepoImpl,
  CommentRepoImpl,
  DivisionRepoImpl,
  PositionRepoImpl,
  UserRepoImpl,
} from '@app/core/api';
import { LoaderComponent } from '@app/ui-kit/shared/components/indicators';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainLayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MatSnackBarModule,
    NotificationComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LoaderComponent,
  ],
  providers: [
    { provide: LanguageService, useClass: LanguageServiceImpl },
    { provide: TimeTrackingRepo, useClass: TimeTrackingRepoImpl },
    { provide: DeviceRepo, useClass: DeviceRepoImpl },
    { provide: CommentRepo, useExisting: CommentRepoImpl },
    { provide: DivisionRepo, useClass: DivisionRepoImpl },
    { provide: PositionRepo, useClass: PositionRepoImpl },
    { provide: UserRepo, useExisting: UserRepoImpl },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: Locale.Ukrainian },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
