import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-mobile-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './mobile-dialog.component.html',
  styleUrls: ['./mobile-dialog.component.scss'],
})
export class MobileDialogComponent {
  @Input() public header: string | null = '';
  @Input() public confirmButtonText = 'confirm';
  @Input() public showFooter = true;
  @Input() public fixedFooter = false;
  @Output() public confirm = new EventEmitter();
  public containerHeight = window.innerHeight;
  private yCoordinate = 0;
  private prevContainerHeight = 0;

  constructor(private dialogRef: DialogRef) {}

  public onConfirm(): void {
    this.confirm.emit();
  }

  public onCloseDialog(): void {
    this.dialogRef.close();
  }

  public swipe(e: TouchEvent, when: string): void {
    const yCoordinate = e.changedTouches[0].clientY;

    if (when === 'start') {
      this.yCoordinate = yCoordinate;
      this.prevContainerHeight = this.containerHeight;
    } else if (when === 'end' || when === 'move') {
      const direction = yCoordinate - this.yCoordinate;

      if (Math.abs(yCoordinate) > 30) {
        this.containerHeight = this.prevContainerHeight - direction;
        if (this.containerHeight < 500) {
          this.onCloseDialog();
        }
      }
    }
  }
}
