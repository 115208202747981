import { Component, Injector, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HasControl } from '@app/ui-kit/shared/models/has-control';

@Component({
  selector: 'app-slide-toggle',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SlideToggleComponent,
      multi: true,
    },
  ],
})
export class SlideToggleComponent extends HasControl implements OnInit, ControlValueAccessor {
  public isActive = false;

  constructor(injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    this.setComponentControl();
  }

  public onChange: any = () => undefined;
  public onTouch: any = () => undefined;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(isActive: boolean): void {
    this.isActive = isActive;
  }

  public onModelChange(e: boolean): void {
    this.isActive = e;
    this.onChange(e);
  }

  public switch(): void {
    if (this.control?.disabled) {
      return;
    }
    this.isActive = !this.isActive;
    this.onChange(this.isActive);
  }
}
