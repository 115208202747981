import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BeforeInstallPromptEvent } from '@app/core/models/interfaces/before-install-prompt-event.interface';

@Component({
  selector: 'app-install-pwa-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  templateUrl: './install-pwa-dialog.component.html',
  styleUrls: ['./install-pwa-dialog.component.scss'],
})
export class InstallPwaDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: { promptEvent: BeforeInstallPromptEvent },
    private dialogRef: DialogRef
  ) {}

  public addApplicationToHomeScreen(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
