import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services';
import { UserRole } from '@app/core/domain';

export const hasRoleGuard = (route: ActivatedRouteSnapshot): boolean => {
  const auth = inject(AuthenticationService);
  const router = inject(Router);
  let isValid = false;
  const allowedRoles = route.data['roles'];
  if (!allowedRoles) {
    return isValid;
  }
  for (const role of allowedRoles as UserRole[]) {
    if (auth.getUser().haveRoles(role)) {
      isValid = true;
      break;
    }
  }
  if (!isValid) {
    router.navigate(['']);
  }
  return isValid;
};
