<div class="dialog">
  <h1 class="dialog__header">{{ 'logoutFromSystem' | translate }}</h1>

  <div class="dialog__content">
    <img ngSrc="assets/images/user.svg" alt="User image" height="156" width="156" />
    <p class="dialog__message">{{ 'confirmLogout' | translate }}</p>
  </div>

  <div class="dialog__actions">
    <app-action-button
      class="dialog__action"
      [label]="'cancel' | translate"
      iconName="simple-close"
      (click)="close()"></app-action-button>
    <app-action-button
      class="dialog__action"
      [label]="'confirm' | translate"
      (click)="logout()"
      iconName="check-outlined"></app-action-button>
  </div>
</div>
