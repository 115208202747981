import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDynamicTableHeaderConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-header-config.interface';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTableClass, IDynamicTableSubheaderConfig } from '@app/ui-kit/shared/components/tables';
import { IsArrayPipe } from '@app/ui-kit/shared/pipes';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-additional-header]',
  standalone: true,
  imports: [CommonModule, TranslateModule, IsArrayPipe],
  templateUrl: './dynamic-table-additional-header.component.html',
  styleUrls: ['./dynamic-table-additional-header.component.scss'],
})
export class DynamicTableAdditionalHeaderComponent implements OnChanges {
  @Input({ required: true }) public config: IDynamicTableHeaderConfig | IDynamicTableSubheaderConfig;
  @Input({ required: false }) public collapsed: boolean;

  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('class.sticky-th') public stickyThClass = false;
  @HostBinding('class.display-none') public classDisplayNone: boolean | undefined = false;
  @HostBinding('class.collapsed') public collapsedClass = false;
  @HostBinding('attr.colspan') public colspan: string | number | undefined;
  @HostBinding('style.left.px') public styleLeftPx: number | undefined;
  @HostBinding('style.min-width') public minWidth: string | undefined;
  @HostBinding('style.max-width') public maxWidth: string | undefined;

  public ngOnChanges(): void {
    this.classes = this.config.cssClass;
    this.stickyThClass = !!this.config.sticky;
    this.collapsedClass = this.collapsed;
    this.classDisplayNone = 'hidden' in this.config ? this.config.hidden : undefined;
    this.colspan = this.config.colspan;
    this.styleLeftPx = this.config.sticky?.left;
    if ('width' in this.config) {
      this.minWidth = this.config.width;
      this.maxWidth = this.config.width;
    }
  }
}
