<div class="month-picker">
  <div class="month-picker__navigation month-picker__prev" (click)="toPrevMonth()">
    <app-icon name="arrow-back-new-rounded-left"></app-icon>
  </div>
  <input
    class="month-picker__value"
    [formControl]="date"
    [matDatepicker]="picker"
    readonly="readonly" />
  <div class="month-picker__navigation month-picker__next" (click)="toNextMonth()">
    <app-icon name="arrow-back-new-rounded-left"></app-icon>
  </div>

  <div class="month-picker__calendar-trigger" (click)="picker.open()">
    <app-icon name="calendar-today-outline-rounded"></app-icon>
  </div>

  <mat-datepicker #picker startView="year" (monthSelected)="selectMonth($event, picker)"></mat-datepicker>
</div>
