<ng-container *ngIf="type !== 'textarea'; else textarea">
  <input
    class="input"
    [class.readonly]="readonly"
    #input
    [class.input_invalid]="error || (control.touched && control.dirty && (control.errors | notEmptyObject))"
    [type]="type === 'password' && !hidden ? 'text' : type"
    [class.input__hidden]="type === 'textHidden' && hidden"
    [placeholder]="placeholder"
    [prefix]="prefix"
    [mask]="value ? mask : ''"
    [(ngModel)]="value"
    (blur)="onBlur()"
    (input)="onInput($event)"
    [autocomplete]="autocomplete"
    [readOnly]="readonly"
  />
</ng-container>

<ng-template #textarea>
  <textarea class="text-area"
            [class.readonly]="readonly"
            [class.input_invalid]="error || (control.touched && control.dirty && (control.errors | notEmptyObject))"
            [placeholder]="placeholder"
            [prefix]="prefix"
            [mask]="value ? mask : ''"
            [(ngModel)]="value"
            [readOnly]="readonly"
            (blur)="onBlur()"
            (input)="onInput($event)"></textarea>
</ng-template>

<div
  class="errors"
  *ngIf="error || (control.touched && control.dirty && (control.errors | notEmptyObject))">
  <app-error *ngIf="error" [error]="error"></app-error>
  <app-error *ngIf="control.errors?.['required']" [error]="'fieldCannotBeEmpty' | translate"></app-error>
  <app-error *ngIf="control.errors?.['minlength'] && !mask"
             [error]="'fieldShouldBeNotLess' | translate:{param: control.errors?.['minlength']?.requiredLength}"></app-error>
  <app-error *ngIf="control.errors?.['minlength'] && mask" [error]="'fieldPhoneIncorrect' | translate"></app-error>
  <app-error *ngIf="control.errors?.['maxlength']"
             [error]="'fieldShouldBeNotMore' | translate:{param: control.errors?.['maxlength']?.requiredLength}"></app-error>
  <app-error *ngIf="control.errors?.['passwordAllowedCharacters']" [error]="'passwordValidationError' | translate"></app-error>
</div>

<span
  *ngIf="!!input?.nativeElement?.value && (type === 'password' || type === 'textHidden')"
  class="show-password"
  (click)="switchShowingPassword()">
  <app-icon class="icon" [name]="hidden ? 'eye-slash' : 'eye'"></app-icon>
</span>
