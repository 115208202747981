<app-action-input
  [(ngModel)]="input"
  icon="search"
  [placeholder]="placeholder"
  (ngModelChange)="onInput($event)"></app-action-input>

<ul class="autocomplete-list scroll-layout">
  <ng-container *ngFor="let item of filteredItems">
    <li
      *ngIf="item.name"
      class="autocomplete-list__item"
      (click)="onSelect(item.value)">
      <app-checkbox
        [checked]="selectedItems | displayFn : isItemSelected : item.value"
        (click)="$event.preventDefault()"></app-checkbox>
      <span>{{ item.name | translate }}</span>
    </li>
  </ng-container>
</ul>
