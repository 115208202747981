import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Icon, icons } from '@app/ui-kit/shared/components/indicators/icon/icons';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class IconComponent {
  @Input()
  public set name(name: Icon) {
    this.element.nativeElement.innerHTML = icons[name] || null;
  }

  @HostBinding('class.icon') classIcon = true;

  constructor(private element: ElementRef) {}
}
