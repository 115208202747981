import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-radio-button-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-button-option.component.html',
  styleUrls: ['./radio-button-option.component.scss'],
})
export class RadioButtonOptionComponent {
  @Input({ required: false }) public label: string | undefined;
  @Input({ required: true }) public selected: boolean;
}
