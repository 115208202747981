<div class="dialog">
  <div class="dialog__message">
    <app-icon class="dialog__icon" name="question-mark-circled"></app-icon>
    {{'newVersionAvailable' | translate}}
  </div>

  <div class="dialog__actions">
    <button class="button button_disabled" (click)="close()">{{'notNow' | translate}}</button>
    <button class="button" (click)="update()">{{'update' | translate}}</button>
  </div>
</div>
