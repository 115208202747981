export class Comment {
  id: number;
  userId?: number;
  topDivId?: number;
  comment: string;
  amount: number;
  commentType: CommentType;
  commentDate: string;
  confirmationDate?: string;
  confirmationType?: CommentConfirmationType;
}

export enum CommentType {
  PENALTY = 'PENALTY',
  RETENTION = 'RETENTION',
  ALLOWANCE = 'ALLOWANCE',
  COMPENSATION = 'COMPENSATION',
}

export enum CommentConfirmationType {
  NONE = 'NONE',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
