import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { IPositionResponse, buildPosition } from '@app/core/api';
import { map, Observable } from 'rxjs';
import { Position, PositionRepo } from '@app/core/domain';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PositionRepoImpl implements PositionRepo {
  private readonly BASE_API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAll(): Observable<Position[]> {
    return this.http
      .get<IPositionResponse[]>(`${this.BASE_API_URL}/hr/positions`)
      .pipe(map((positions) => positions.map((position) => buildPosition(position))));
  }
}
