<div class="dialog" [style.width]="width + 'px'"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragBoundary=".cdk-overlay-container">
  <h2 class="dialog__header">
    <app-icon cdkDragHandle name="move" class="move"  [title]="'move' | translate"></app-icon>
    {{header}}
    <app-icon name="close-outlined" class="close" (click)="close()" [title]="'close' | translate"></app-icon>
  </h2>
  <ng-content></ng-content>
</div>
