<div class="menu-trigger" (click)="switchMenu()">
  <app-icon name="menu"></app-icon>
</div>

<div class="menu" [class.menu-slide-in]="showMenu">
  <div class="menu__header">
    <app-logo size="small"></app-logo>
    <div class="menu__close" (click)="switchMenu()">
      <app-icon name="close-outlined"></app-icon>
    </div>
  </div>
  <app-menu mode="minimal" (menuNavigation)="switchMenu()"></app-menu>
</div>
