import { ChangeDetectionStrategy, Component, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DateFilterFn,
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { CUSTOM_DATE_FORMAT } from '@app/ui-kit/shared/components/controls/date-picker/date-format';
import { asyncScheduler, Subject, takeUntil } from 'rxjs';
import { LanguageService } from '@app/core/services';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { NgIf } from '@angular/common';
import { CustomDateAdapter } from '@app/ui-kit/shared/components/controls/date-picker/custom-date-adapter';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { HasControl } from '@app/ui-kit/shared/models/has-control';
import { ErrorComponent } from '@app/ui-kit/shared/components/indicators/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageUtils } from '@app/ui-kit/shared/utils/language.utils';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [
    MatDatepickerModule,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    PipesModule,
    ErrorComponent,
    TranslateModule,
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatePickerComponent,
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
  ],
})
export class DatePickerComponent extends HasControl implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public placeholder = '';
  @Input() public mode: 'date' | 'month' = 'date';
  @Input() public colorTheme: 'light' | 'primary' = 'light';
  @Input({ required: false }) public maxDate: DateTime | null;
  @Input({ required: false }) minDate: DateTime | null;
  @Input({ required: false }) dateFilter: DateFilterFn<DateTime | null>;
  @Input({ required: false }) readonly = false;

  private _value: string | undefined = '';
  private destroy$ = new Subject<void>();

  public set value(value: string | undefined) {
    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }

  public get value(): string | undefined {
    return this._value;
  }

  constructor(
    private adapter: DateAdapter<CustomDateAdapter>,
    private langService: LanguageService,
    injector: Injector,
    @Inject(MAT_DATE_FORMATS) public data: MatDateFormats
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.setComponentControl();
    this.langService.currentLanguage$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      this.adapter.setLocale(LanguageUtils.getLocale(lang));
      this.adapter.getDayOfWeekNames = LanguageUtils.getDayOfWeekNames(lang);
    });
    if (this.mode === 'month') {
      this.data.display.dateInput = 'LLLL yyyy';
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChange: (val: string | undefined) => undefined = () => undefined;
  public onTouch: (val: string | undefined) => undefined = () => undefined;

  public registerOnChange(fn: (val: string | undefined) => undefined): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => undefined): void {
    this.onTouch = fn;
  }

  public clear(): void {
    this.writeValue();
  }

  public writeValue(date?: MatDatepickerInputEvent<DateTime> | string): void {
    if (!date) {
      this.value = undefined;
      return;
    }

    if (typeof date === 'string') {
      this.value = date;
    } else {
      if (!date.value) {
        return;
      }
      this.value = date.value.startOf('day').toISODate()?.toString() ?? '';
    }
  }

  public selectMonth(date: DateTime, picker?: MatDatepicker<CustomDateAdapter>): void {
    // workaround for avoid blinking before picker close
    if (picker) {
      picker.close();
      picker.panelClass = 'calendar-panel-hidden';
      asyncScheduler.schedule(() => (picker.panelClass = ''), 0);
    }
    this.value = date.startOf('day').toISODate()?.toString() ?? '';
  }
}
