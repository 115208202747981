import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, shareReplay, tap } from 'rxjs';
import { LayoutService } from '@app/core/services';
import { DeviceUtils } from '@app/ui-kit/shared/utils/device.utils';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements AfterViewInit {
  public isDesktop$ = this.layoutService.isDesktop$.pipe(distinctUntilChanged()).pipe(
    tap(() => {
      this.cdr.detectChanges();
    }),
    shareReplay()
  );
  public isMobile$ = this.layoutService.isMobile$.pipe();
  public menuCollapsed$ = this.layoutService.isMenuCollapsed$;
  public window = window;
  public showTimeTracker = DeviceUtils.isMobileDevice();
  public loading: undefined | boolean;
  private destroyRef = inject(DestroyRef);

  constructor(private layoutService: LayoutService, private cdr: ChangeDetectorRef, private router: Router) {
    this.router.events
      .pipe(
        filter((event) => {
          return (
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
          );
        }),
        debounceTime(0),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((routerEvent) => {
        this.checkRouterEvent(routerEvent as RouterEvent);
      });
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  private checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }
}
