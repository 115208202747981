export enum AppSection {
  USER = 'user',
  PROFILE = 'profile',
  INCOME = 'income',
  SALARIES = 'salaries',
  TIMESHEET = 'timesheet',
  TIME_TRACKING = 'time-tracking',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  TAXES = 'taxes',
  TOP_DIVISIONS = 'top-divisions'
}
