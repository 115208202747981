import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string; status: 'error' | 'success' },
    private snackBar: MatSnackBar
  ) {}

  public close(): void {
    this.snackBar.dismiss();
  }
}
