import { Image, User } from '@app/core/domain';
import { IImageUserResponse, IUserResponse, buildPosition, buildDivision, buildSubDivision } from '@app/core/api';

export const buildUser = (input: IUserResponse): User => {
  const result = new User();
  result.id = input.id;
  result.name = input.name;
  result.surname = input.surname;
  result.patronymic = input.patronymic;
  result.phoneNumber = input.phoneNumber;
  result.image = buildImage(input.image);
  result.additionalPhoneNumber = input.additionalPhoneNumber;
  result.address = input.address;
  result.position = input.position ? buildPosition(input.position) : input.position;
  result.topDivision = input.topDivision ? buildDivision(input.topDivision) : input.topDivision;
  result.subDivision = input.subDivision ? buildSubDivision(input.subDivision) : input.subDivision;
  result.traceable = input.traceable;
  return result;
};

export const buildImage = (input?: IImageUserResponse): Image | undefined => {
  if (!input) {
    return;
  }
  const result = new Image();
  result.uuid = input.uuid;
  result.name = input.name;
  result.size = input.size;
  result.path = input.path;
  result.contentType = input.contentType;
  return result;
};
