<div [class.container]="isDesktop$ | async" [class.mobile-container]="isMobile$ | async">
  <app-menu class="menu" [class.menu_collapsed]="menuCollapsed$ | async" *ngIf="isDesktop$ | async"></app-menu>

  <div class="mobile-container__header" *ngIf="isMobile$ | async">
    <div class="mobile-container__buttons">
      <app-mobile-menu></app-mobile-menu>
      <app-time-tracker *ngIf="showTimeTracker" class="mobile-container__time-tracker"></app-time-tracker>
    </div>
    <app-logo class="mobile-container__logo" size="small"></app-logo>
    <app-select-language class="mobile-container__language-select"></app-select-language>
  </div>

  <div class="content"
       [class.content_extended]="menuCollapsed$ | async"
       [style.max-height]="(isMobile$ | async) ? 'calc(' + window.innerHeight + 'px - 100px)' : ''">
    <app-loader [isFullscreen]="true" *ngIf="loading"></app-loader>
    <router-outlet></router-outlet>
  </div>
</div>
