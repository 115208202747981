import { MobileOS } from '@app/ui-kit/shared/models/mobile-os';

export class DeviceUtils {
  private static userAgent = navigator.userAgent;

  public static isMobileDevice = (): boolean => {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regexs.some((b) => this.userAgent.match(b));
  };

  public static getMobileOS = (): MobileOS | undefined => {
    if (this.isMobileDevice()) {
      if (/windows phone/i.test(this.userAgent)) return MobileOS.WindowsPhone;
      else if (/android/i.test(this.userAgent)) return MobileOS.Android;

      if (/iPad|iPhone|iPod/.test(this.userAgent) && !(window as any).MSStream) return MobileOS.iOS;
      return MobileOS.Unknown;
    } else return undefined;
  };

  public static isAndroidMobileDevice = DeviceUtils.getMobileOS() === MobileOS.Android;
  public static isAppleMobileDevice = DeviceUtils.getMobileOS() === MobileOS.iOS;
}
