import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { DynamicTableClass, IDynamicTableCollapsibleConfig } from '@app/ui-kit/shared/components/tables';
import { NgIf } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-header-collapsed]',
  standalone: true,
  templateUrl: './dynamic-table-header-collapsed.component.html',
  styleUrls: ['./dynamic-table-header-collapsed.component.scss'],
  imports: [NgIf, TranslateModule],
})
export class DynamicTableHeaderCollapsedComponent implements OnChanges {
  @Input({ required: true }) public config: IDynamicTableCollapsibleConfig;
  @Input({ required: false }) public subheaderRowsLength: number | undefined;
  @Input({ required: false }) public rowspanAdditional = 1;

  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('attr.rowspan') public rowspan: number | undefined;
  @HostBinding('class.header-collapsed') public headerCollapsedClass = true;

  public ngOnChanges(): void {
    this.classes = this.config.cssClass;
    this.rowspan = this.rowspanAdditional + (this.subheaderRowsLength ?? 0);
  }
}
