import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MainLayoutComponent } from './main-layout.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from '@app/ui-kit/layouts/main/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { LogoComponent } from '@app/ui-kit/shared/components/indicators/logo/logo.component';
import { LogoutDialogComponent } from '@app/ui-kit/layouts/main/dialogs/logout-dialog/logout-dialog.component';
import { ActionButtonComponent } from '@app/ui-kit/shared/components/controls/action-button/action-button.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { MobileLogoutDialogComponent } from '@app/ui-kit/layouts/main/dialogs/mobile-logout-dialog/mobile-logout-dialog.component';
import { SelectLanguageComponent } from '@app/core/components/select-language/select-language.component';
import { MobileDialogComponent } from '@app/ui-kit/shared/components/dialogs/mobile-dialog/mobile-dialog.component';
import { FadeOutOnScrollDirective } from '@app/ui-kit/shared/directives/fade-out-on-scroll.directive';
import { TimeTrackerComponent } from '@app/core/components/time-tracker/time-tracker.component';
import { LoaderComponent } from '@app/ui-kit/shared/components/indicators';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    MainLayoutComponent,
    MenuComponent,
    LogoutDialogComponent,
    MobileMenuComponent,
    MobileLogoutDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LogoComponent,
    IconComponent,
    TranslateModule,
    ActionButtonComponent,
    NgOptimizedImage,
    SelectLanguageComponent,
    MobileDialogComponent,
    FadeOutOnScrollDirective,
    TimeTrackerComponent,
    LoaderComponent,
    PipesModule,
  ],
})
export class MainLayoutModule {}
