import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AsyncPipe,
  DecimalPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { TableUtils } from '@app/ui-kit/shared/utils/table.utils';
import { TableComponent } from '@app/ui-kit/shared/components/tables/table/table.component';
import { asyncScheduler, debounceTime, map, Observable, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { IFixedHeaderConfig } from '@app/ui-kit/shared/components/tables/table/models/fixed-header-config.interface';
import { IDynamicTableSubheaderConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-subheader-config.interface';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { ObjectUtils } from '@app/ui-kit/shared/utils/object.utils';
import { IDynamicTableFooterConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-footer-config.interface';
import { ITableConfig } from '@app/ui-kit/shared/components/tables/table/models/table-config.interface';
import { DynamicTableService } from '@app/ui-kit/shared/components/tables/dynamic-table/dynamic-table.service';
import { ColumnFilterComponent } from '@app/ui-kit/shared/components/tables/table/components/column-filter/column-filter.component';
import { TemplateHeaderDirective } from '@app/ui-kit/shared/components/tables/table/directives/template-header.directive';
import { IEditableColumnMeta } from '@app/ui-kit/shared/components/tables/table/models/editable-column-meta.interface';
import { ITableDialogData } from '@app/ui-kit/shared/components/tables/dynamic-table/model/table-dialog-data.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ITableRowConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/table-row-config';
import { ITableData } from '@app/ui-kit/shared/components/tables/table/models/table-data.interface';
import { ITableDataGroupHeader } from '@app/ui-kit/shared/components/tables/table/models/table-data-group-header.interface';
import { IDynamicTableHeaderConfig } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-header-config.interface';
import { IDynamicTableCollapsibleConfig } from '@app/ui-kit/shared/components/tables';
import { VarDirective } from '@app/ui-kit/shared/directives';
import { DynamicTableAdditionalHeaderComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-additional-header/dynamic-table-additional-header.component';
import { DynamicTableAdditionalHeaderCollapsibleComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-additional-header-collapsible/dynamic-table-additional-header-collapsible.component';
import { DynamicTableHeaderCollapsedComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-header-collapsed/dynamic-table-header-collapsed.component';
import { DynamicTableHeaderComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-header/dynamic-table-header.component';
import { DynamicTableHeaderCollapsibleComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-header-collapsible/dynamic-table-header-collapsible.component';
import { ColumnCollapseComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/column-collapse/column-collapse.component';
import { DynamicTableDataGroupHeaderComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-data-group-header/dynamic-table-data-group-header.component';
import { DynamicTableDataComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-data/dynamic-table-data.component';
import { DynamicTableInnerDataComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/dynamic-table-inner-data/dynamic-table-inner-data.component';
import { DataArray } from '@app/ui-kit/shared/components/tables/table/models/table-data-array';
import { ArrayUtils } from '@app/ui-kit/shared/utils';
import {
  ITableDataGroupHeaderRow,
  ITableDataGroupSubheaderRow,
  ITableDataRow,
  TableRowType,
} from '@app/ui-kit/shared/components/tables/table/models/table-row.interface';

/*
                                  Table structure
  *********************************************************************************
  *            HEADER (dynamic-table-additional-header) (optional)                *
  *********************************************************************************
  *  COLUMN 1 (app-dynamic-table-header)  | COLUMN n (app-dynamic-table-header)   *
  *********************************************************************************
  *          SUBHEADER (dynamic-table-additional-header) (optional)               *
  *********************************************************************************

  ---------------------------------------------------------------------------------
  |        Data group header 1 (dynamic-table-data-group-header) (optional)       |
  ---------------------------------------------------------------------------------
  |               column 1               |                column 2                |
  ---------------------------------------------------------------------------------
  |               column 1               |                column 2                |
  ---------------------------------------------------------------------------------
  |        Data group header 2 (dynamic-table-data-group-header) (optional)        |
  ---------------------------------------------------------------------------------
  |               column 1               |                column 2                |
  ---------------------------------------------------------------------------------
  |               column 1               |                column 2                |
  ---------------------------------------------------------------------------------

  *********************************************************************************
  *                               FOOTER (optional)                               *
  *********************************************************************************
 */

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PipesModule,
    NgClass,
    IconComponent,
    ColumnFilterComponent,
    TemplateHeaderDirective,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    NgForOf,
    NgIf,
    DecimalPipe,
    TableComponent,
    TranslateModule,
    NgStyle,
    AsyncPipe,
    VarDirective,
    DynamicTableAdditionalHeaderComponent,
    DynamicTableAdditionalHeaderCollapsibleComponent,
    DynamicTableHeaderCollapsedComponent,
    DynamicTableHeaderComponent,
    DynamicTableHeaderCollapsibleComponent,
    ColumnCollapseComponent,
    DynamicTableDataGroupHeaderComponent,
    DynamicTableDataComponent,
    DynamicTableInnerDataComponent,
  ],
  providers: [DynamicTableService],
})
export class DynamicTableComponent<TableData extends ITableData> implements AfterViewInit, OnDestroy {
  public _enableVirtualScroll = false;
  public _data: DataArray<TableData>;
  @Input({ required: true }) public columns: ITableConfig<TableData>[];

  @Input({ required: true })
  public set data(data: TableData[] | DataArray<TableData>) {
    this._data =
      ArrayUtils.getArrayDepth(data) >= 2 ? (data as DataArray<TableData>) : ([data] as DataArray<TableData>);
    // check if table view exists and columns weren't collapsed programmatically
    if (this.appTable && !this.wereColumnsCollapsedByUser) {
      this.collapseColumnsByDefault();
    }
  }

  @Input({ required: false }) public rows?: ITableRowConfig | undefined;
  @Input({ required: false }) public dataGroupsHeader: ITableDataGroupHeader | undefined;
  @Input({ required: false }) public headerColumns?: IDynamicTableHeaderConfig[][] | undefined;
  @Input({ required: false }) public subheaderColumns?: IDynamicTableSubheaderConfig[][] | undefined;
  @Input({ required: false }) public footerColumns?: IDynamicTableFooterConfig[][] | undefined;
  @Input({ required: false }) public fixedHeader?: IFixedHeaderConfig | undefined;
  @Input({ required: false }) public showIndex = false;
  @Input({ required: false }) public minWidth100 = true;
  @Input({ required: false }) public parentContainerSelector = 'app-table-layout-content';
  @Input({ required: false }) public disableNoDataLogic = false;
  @Input({ required: false }) public enablePagination = false;

  @Input({ required: false })
  public set enableVirtualScroll(enableVirtualScroll: boolean) {
    this._enableVirtualScroll = enableVirtualScroll;
    this.withVirtualScrollClass = this._enableVirtualScroll;
  }

  @ViewChild(TableComponent) public appTable: TableComponent<TableData>;
  @Output() dataEdit = new EventEmitter<{ meta: IEditableColumnMeta; data: TableData | TableData[] }>();
  @Output() public rowSelect = new EventEmitter<TableData | null>();
  @Output() public collapsed = new EventEmitter<IDynamicTableCollapsibleConfig[]>();
  @HostBinding('class.with-virtual-scroll') withVirtualScrollClass = false;
  public dataUpdate = new Date();
  public getRowStylesConfig = TableUtils.getRowStylesConfig;
  public getIndexStylesConfig = TableUtils.getIndexStylesConfig;
  public activeField = '';
  public selectedRowId: number | string | null = null;
  public collapsedColumns$: Observable<IDynamicTableCollapsibleConfig[]>;
  public collapsedDataGroups$: Observable<number[]> = this.dynamicTableService.collapsibleDataGroupsSource$;
  public readonly TableRowType = TableRowType;
  private wereColumnsCollapsedByUser = false;
  private parentContainer: Element | null = null;
  private destroy$ = new Subject<void>();
  public currentVisibleScrolledIndex = 0;

  public trackByColumn = (_: number, column: ITableConfig<TableData>): string => {
    return column.fieldName;
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private dynamicTableService: DynamicTableService,
    private elementRef: ElementRef
  ) {}

  public onScrollIndexChanged(data: number): void {
    this.currentVisibleScrolledIndex = data;
  }

  public toTableRowType = (
    item: unknown
  ): ITableDataRow<TableData> | ITableDataGroupHeaderRow | ITableDataGroupSubheaderRow => {
    return item as ITableDataRow<TableData> | ITableDataGroupHeaderRow | ITableDataGroupSubheaderRow;
  };

  public toHeaderRowType = (
    item: ITableDataRow<TableData> | ITableDataGroupHeaderRow | ITableDataGroupSubheaderRow
  ): ITableDataGroupHeaderRow => {
    return item as ITableDataGroupHeaderRow;
  };

  public toSubheaderRowType = (
    item: ITableDataRow<TableData> | ITableDataGroupHeaderRow | ITableDataGroupSubheaderRow
  ): ITableDataGroupSubheaderRow => {
    return item as ITableDataGroupSubheaderRow;
  };

  public toDataRowType = (
    item: ITableDataRow<TableData> | ITableDataGroupHeaderRow | ITableDataGroupSubheaderRow
  ): ITableDataRow<TableData> => {
    return item as ITableDataRow<TableData>;
  };

  public isDataEmpty = (data: TableData[] | DataArray<TableData>): boolean => {
    if (this.disableNoDataLogic) {
      return false;
    }
    if (Array.isArray(data[0])) {
      return (data as TableData[][]).every((item) => !item.length);
    } else {
      return !(data as TableData[]).length;
    }
  };

  public ngAfterViewInit(): void {
    this.collapsedColumns$ = this.dynamicTableService.collapsibleColumnSource$.pipe(
      tap((data) => {
        if (data.collapsedByUser) {
          this.collapsed.emit(data.config);
          this.wereColumnsCollapsedByUser = true;
        }
      }),
      map((data) => data.config),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    this.collapsedColumns$.pipe(debounceTime(0), takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.markForCheck();
    });
    // recall formatting data when data changed
    // ToDo: optimisation?
    this.appTable?.tableService.editSource$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.dataUpdate = new Date();
        this.cdr.markForCheck();
      },
    });
    this.parentContainer = (this.elementRef.nativeElement as HTMLElement).closest(this.parentContainerSelector);
    this.collapseColumnsByDefault();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getIndex = (dataIndex: number): number => {
    if (this.enablePagination && this.appTable?.paginationControl.value) {
      const { page, pageSize } = this.appTable.paginationControl.value;
      return page === 1 ? dataIndex : (page - 1) * pageSize + dataIndex;
    } else {
      return dataIndex;
    }
  };

  public haveInnerColumns = (columns: ITableConfig<TableData>[]): boolean => {
    return columns.some((column) => column.columns);
  };

  public resolveFieldName = (column: ITableConfig<TableData>, innerColumn?: ITableConfig<TableData>): string => {
    return !innerColumn ? column.fieldName : (column.fieldName ? column.fieldName + '.' : '') + innerColumn.fieldName;
  };

  public isHeaderCollapsed = (
    collapsedColumns: IDynamicTableCollapsibleConfig[],
    selectedColumn: IDynamicTableHeaderConfig
  ): boolean => {
    if (!collapsedColumns || !selectedColumn.collapsible) {
      return false;
    }
    return collapsedColumns.some((column) => column.id === selectedColumn.collapsible?.id);
  };

  public isColumnNotCollapsed = (
    collapsedColumns: IDynamicTableCollapsibleConfig[],
    index: number,
    parentColumn?: ITableConfig<TableData>
  ): boolean => {
    return !this.isColumnCollapsed(collapsedColumns, index, parentColumn);
  };

  public isColumnCollapsed = (
    collapsedColumns: IDynamicTableCollapsibleConfig[],
    index: number,
    parentColumn?: ITableConfig<TableData>
  ): boolean => {
    if (!collapsedColumns) {
      return false;
    }
    // case when we collapse parent column of inner columns
    if (parentColumn && parentColumn.collapsible) {
      return collapsedColumns.some((column) => column.id === parentColumn.collapsible?.id);
      // case when we collapse using additional header
    } else {
      for (const column of collapsedColumns) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (index >= column.fromColumn && index <= column.toColumn) {
          return true;
        }
      }
    }
    return false;
  };

  public showDataCollapsed = (
    collapsedColumns: IDynamicTableCollapsibleConfig[],
    index: number,
    parentColumn?: ITableConfig<TableData>
  ): boolean => {
    if (!collapsedColumns) {
      return false;
    }
    // case when we collapse parent column of inner columns
    if (parentColumn && parentColumn.collapsible) {
      return collapsedColumns.some((column) => column.id === parentColumn.collapsible?.id);
      // case when we collapse using additional header
    } else {
      for (const column of collapsedColumns) {
        if (index === column.fromColumn) {
          return true;
        }
      }
    }
    return false;
  };

  public getCollapsedColumn = (
    collapsedColumns: IDynamicTableCollapsibleConfig[],
    index: number,
    parentColumn?: ITableConfig<TableData>
  ): IDynamicTableCollapsibleConfig | undefined => {
    if (!collapsedColumns) {
      return;
    }
    // case when we collapse parent column of inner columns
    if (parentColumn && parentColumn.collapsible) {
      return collapsedColumns.find((column) => column.id === parentColumn.collapsible?.id);
      // case when we collapse using additional header
    } else {
      for (const column of collapsedColumns) {
        if (index === column.fromColumn) {
          return column;
        }
      }
    }
    return;
  };

  // update collapsed columns by ids from outside
  public updateCollapsedColumns(columnsIds: string[]): void {
    const collapsedColumns: IDynamicTableCollapsibleConfig[] = [];
    this.headerColumns?.forEach((row) => {
      const columns = row.filter((column) => columnsIds.includes(column.collapsible?.id ?? ''));
      if (columns) {
        collapsedColumns.push(...columns.map((column) => column.collapsible!));
      }
    });
    this.dynamicTableService.updateCollapsedColumns(collapsedColumns);
    this.wereColumnsCollapsedByUser = true;
  }

  public switchCollapsibleDataGroup(dataGroupIndex: number): void {
    this.dynamicTableService.onSwitchCollapsibleDataGroup(dataGroupIndex);
    asyncScheduler.schedule(() => {
      this.appTable.scrollViewport?.checkViewportSize();
    }, 0);
  }

  public isDataGroupCollapsed = (collapsedDataGroups: number[], dataGroupIndex: number): boolean => {
    return collapsedDataGroups.includes(dataGroupIndex);
  };

  public dataClick(
    target: EventTarget | null,
    data: TableData,
    rowIndex: number,
    column: ITableConfig<TableData>,
    innerColumn?: ITableConfig<TableData>
  ): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.rows?.[data.id]?.selectable) {
      this.selectedRowId = this.selectedRowId === data.id ? null : data.id;
      this.rowSelect.emit(this.selectedRowId ? data : null);
      return;
    }
    if ((!column.dialog && !innerColumn?.dialog) || !target) {
      return;
    }
    const dialogGetter = innerColumn && innerColumn.dialog ? innerColumn!.dialog : column.dialog!;
    const dialogComponent = dialogGetter(data);
    if (!dialogComponent) return;

    this.setActiveField(data.id, column, innerColumn);

    const dialog = this.dynamicTableService.openDialog(
      target as HTMLElement,
      dialogComponent,
      this.getDialogData(data, column, innerColumn)
    );

    if (dialog) {
      dialog.closed.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.resetActiveField();
        this.cdr.markForCheck();
      });
    }
  }

  public resetSelectedRow(): void {
    this.selectedRowId = null;
    this.rowSelect.emit(null);
  }

  private getDialogData(
    data: TableData,
    column: ITableConfig<TableData>,
    innerColumn?: ITableConfig<TableData>
  ): ITableDialogData<TableData> {
    const field = this.resolveFieldName(column, innerColumn);
    return {
      dt: this.appTable,
      data: data,
      field,
      value: ObjectUtils.resolveFieldData(data, field),
    };
  }

  private setActiveField(
    id: number | string,
    column: ITableConfig<TableData>,
    innerColumn?: ITableConfig<TableData>
  ): void {
    const field = this.resolveFieldName(column, innerColumn);
    this.activeField = field + id;
  }

  private resetActiveField(): void {
    this.activeField = '';
  }

  public scrollParentContainerToTheTop(): void {
    if (!this.parentContainer || this._enableVirtualScroll) {
      return;
    }
    this.parentContainer.scroll({ top: 0 });
  }

  public onDataEdit(data: { meta: IEditableColumnMeta; data: TableData | TableData[] }): void {
    this.dataEdit.emit(data);
  }

  private collapseColumnsByDefault(): void {
    let collapsed = false;
    this.headerColumns?.forEach((row) => {
      row.forEach((column) => {
        collapsed = this.updateIsColumnCollapsedByDefault(column) || collapsed;
      });
    });
    this.columns.forEach((column) => {
      collapsed = this.updateIsColumnCollapsedByDefault(column) || collapsed;
    });
    if (collapsed) {
      this.cdr.detectChanges();
    }
  }

  private updateIsColumnCollapsedByDefault(column: ITableConfig<TableData> | IDynamicTableHeaderConfig): boolean {
    let updated = false;
    const shouldCollapse = column.collapsible?.collapsedByDefault;
    if (column.collapsible && shouldCollapse !== undefined) {
      updated = true;
      if (shouldCollapse) {
        this.dynamicTableService.onCollapseColumn(column.collapsible, false);
      } else {
        this.dynamicTableService.onExpandColumn(column.collapsible, false);
      }
    }
    return updated;
  }
}
