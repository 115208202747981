import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTableClass, ITableDataGroupHeaderConfig } from '@app/ui-kit/shared/components/tables';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-data-group-header]',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './dynamic-table-data-group-header.component.html',
  styleUrls: ['./dynamic-table-data-group-header.component.scss'],
})
export class DynamicTableDataGroupHeaderComponent implements OnChanges {
  @Input({ required: true }) public config: ITableDataGroupHeaderConfig;

  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('attr.colspan') public colspan: string | number | undefined;
  @HostBinding('style.min-width') public minWidth: string | undefined;
  @HostBinding('style.max-width') public maxWidth: string | undefined;
  @HostBinding('class.sticky-data-group-header') public stickyClass = false;
  @HostBinding('style.left.px') public styleLeftPx: number | undefined;

  public ngOnChanges(): void {
    this.classes = this.config.cssClass;
    this.colspan = this.config.colspan;
    this.minWidth = this.config.width;
    this.maxWidth = this.config.width;
    this.stickyClass = !!this.config.sticky;
    this.styleLeftPx = this.config.sticky?.left;
  }
}
