import { DynamicTableClass } from '@app/ui-kit/shared/components/tables/dynamic-table/model/dynamic-table-class';

export interface IDynamicTableFooterConfig {
  name: string;
  sticky?: { left: number };
  width?: string;
  cssClass?: DynamicTableClass | DynamicTableClass[];
  indexCssClass?: DynamicTableClass | DynamicTableClass[]; // should be in the first column config
  colspan?: number | string;
  hideIndex?: boolean;
  hidden?: boolean;
}
