<div class="field"
     [class.field_readonly]="readonly"
     [class.field_without-clear]="disableClear"
     [class.field_activated]="isSearchInputActive"
     [class.field_opened]="isOpen"
     [class.field_invalid]="control?.touched && control?.dirty && (control?.errors | notEmptyObject)"
     cdkOverlayOrigin #trigger="cdkOverlayOrigin"
     (click)="showOptions()"
     #field>
  <app-icon [ngClass]="enableSearch ? 'field__search' : 'field__switch'"
            [name]="enableSearch ? 'search' : 'arrow-back-new-rounded-down'"></app-icon>

  <input *ngIf="!enableSearch; else searchField"
         class="field__input"
         readonly="readonly"
         [title]="value?.name ?? '' | translate"
         [placeholder]="placeholder"
         [value]="(value?.name ?? '' | translate) ?? null">
  <ng-template #searchField>
    <input *ngIf="enableSearch"
           #searchFieldComponent
           class="field__search-input"
           [title]="value?.name ?? '' | translate"
           [placeholder]="placeholder"
           [readOnly]="!isSearchInputActive"
           [spellcheck]="false"
           [(ngModel)]="searchText">
  </ng-template>

  <app-icon *ngIf="value && !disableClear"
            class="field__clear"
            name="close"
            [title]="'delete' | translate"
            (click)="clear($event)"></app-icon>
</div>

<div
  class="errors"
  *ngIf="control && control.touched && control.dirty && (control.errors | notEmptyObject)">
  <app-error *ngIf="control.errors?.['required']" [error]="'fieldCannotBeEmpty' | translate"></app-error>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="options-overlay"
  (overlayOutsideClick)="hideOptions()"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen">
  <div class="options" [style.width]="field.offsetWidth + 'px'" [style.max-height.px]="optionsMaxHeight">
    <ng-container *ngIf="(options ?? []) | searchFilter : (value?.name === searchText ? '' : searchText) : ['name'] as filteredOptions">
      <div class="option"
           *ngFor="let option of filteredOptions"
           (click)="writeValue(option)"
           [class.option_selected]="value?.id === option.id"
      >{{option.name | translate}}</div>
      <div class="option option_empty" *ngIf="!filteredOptions?.length">{{'noData' | translate}}</div>
    </ng-container>
  </div>
</ng-template>
