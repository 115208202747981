import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemUser, UserAccess } from '@app/core/models/classes/system-user';
import { Division, UserRole } from '@app/core/domain';
import { asyncScheduler, BehaviorSubject, filter, map } from 'rxjs';
import { GlobalFiltersService } from '@app/core/services/global-filters.service';

export const TOKEN_KEY = 'auth-token';
export const USER_KEY = 'app-user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly DELAY_AFTER_LOGIN_IN_MS = 1000;
  private _user: SystemUser;
  private _isAuthenticated = new BehaviorSubject<boolean | null>(null);
  public isAuthenticated$ = this._isAuthenticated.asObservable().pipe(
    filter((val) => val !== null),
    map((val) => val as boolean)
  );
  protected storage: 'session' | 'local' | undefined = undefined;

  public isAuthenticated(): boolean {
    // ToDo: check if token is expired
    return !!this.getToken();
  }

  private set user(user: SystemUser) {
    this._user = user;
  }

  private get user(): SystemUser {
    if (!this.storage || !this.isAuthenticated()) {
      return this.getEmptyUser();
    }
    return this._user;
  }

  constructor(private router: Router, private globalFiltersService: GlobalFiltersService) {
    this.initialize();
  }

  public authorize(token: string, user: SystemUser, rememberMe: boolean): void {
    if (rememberMe) {
      this.storage = 'local';
      window.localStorage.setItem(TOKEN_KEY, token);
      window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    } else {
      this.storage = 'session';
      window.sessionStorage.setItem(TOKEN_KEY, token);
      window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }
    this.user = user;
    this.globalFiltersService.resetState();
    asyncScheduler.schedule(() => {
      this._isAuthenticated.next(true);
    }, this.DELAY_AFTER_LOGIN_IN_MS);
  }

  public getToken(): string | null {
    if (!this.storage) {
      return null;
    }
    return this.storage === 'local' ? window.localStorage.getItem(TOKEN_KEY) : window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getUser(): SystemUser {
    return this.user;
  }

  public getRoles(): UserRole[] {
    return this.user.roles;
  }

  public getDivision(): Division | null {
    return this.user.topDivision ?? null;
  }

  public getAccess(): UserAccess[] | null {
    return this.user.access ?? null;
  }

  public getAllAvailableDivisions(): Division[] | null {
    return this.user.getAllAvailableDivisions();
  }

  public logout(navigateToLogin = true): void {
    if (this.storage === 'local') {
      window.localStorage.removeItem(TOKEN_KEY);
      window.localStorage.removeItem(USER_KEY);
    } else {
      window.sessionStorage.removeItem(TOKEN_KEY);
      window.sessionStorage.removeItem(USER_KEY);
    }
    this.user = this.getEmptyUser();
    this.globalFiltersService.resetState();
    this._isAuthenticated.next(false);
    if (navigateToLogin) {
      this.router.navigate(['/auth']);
    }
  }

  private initialize(): void {
    this.storage = window.localStorage.getItem(TOKEN_KEY)
      ? 'local'
      : window.sessionStorage.getItem(TOKEN_KEY)
      ? 'session'
      : undefined;

    const storedUser =
      this.storage === 'local' ? window.localStorage.getItem(USER_KEY) : window.sessionStorage.getItem(USER_KEY);
    if (storedUser) {
      try {
        const userFromStorage = JSON.parse(storedUser) as SystemUser;
        this.user = new SystemUser(
          userFromStorage.userId,
          userFromStorage.fullName,
          userFromStorage.roles,
          userFromStorage.topDivision,
          userFromStorage.subDivision,
          userFromStorage.access
        );
        this._isAuthenticated.next(true);
      } catch (err) {
        console.error('Error during initialization user:', err);
      }
    } else {
      this._isAuthenticated.next(false);
    }
  }

  private getEmptyUser(): SystemUser {
    return new SystemUser();
  }
}
