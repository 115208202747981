export class ObjectUtils {
  public static resolveFieldData(data: any, field: any): string | number | null {
    if (data && field) {
      if (this.isFunction(field)) {
        return field(data);
      } else if (field.indexOf('.') == -1) {
        return data[field];
      } else {
        const fields: string[] = field.split('.');
        let value = data;
        for (let i = 0, len = fields.length; i < len; ++i) {
          if (value == null) {
            return null;
          }
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }

  public static isFunction(obj: any): boolean {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  }

  public static removeAccents(str: string): string {
    if (str && str.search(/[\xC0-\xFF]/g) > -1) {
      str = str
        .replace(/[\xC0-\xC5]/g, 'A')
        .replace(/[\xC6]/g, 'AE')
        .replace(/[\xC7]/g, 'C')
        .replace(/[\xC8-\xCB]/g, 'E')
        .replace(/[\xCC-\xCF]/g, 'I')
        .replace(/[\xD0]/g, 'D')
        .replace(/[\xD1]/g, 'N')
        .replace(/[\xD2-\xD6\xD8]/g, 'O')
        .replace(/[\xD9-\xDC]/g, 'U')
        .replace(/[\xDD]/g, 'Y')
        .replace(/[\xDE]/g, 'P')
        .replace(/[\xE0-\xE5]/g, 'a')
        .replace(/[\xE6]/g, 'ae')
        .replace(/[\xE7]/g, 'c')
        .replace(/[\xE8-\xEB]/g, 'e')
        .replace(/[\xEC-\xEF]/g, 'i')
        .replace(/[\xF1]/g, 'n')
        .replace(/[\xF2-\xF6\xF8]/g, 'o')
        .replace(/[\xF9-\xFC]/g, 'u')
        .replace(/[\xFE]/g, 'p')
        .replace(/[\xFD\xFF]/g, 'y');
    }

    return str;
  }

  public static set = (
    object: any,
    propertyStr: string,
    value: any,
    properties = propertyStr.split('.'),
    i = 0
  ): void => {
    if (i === properties.length - 1) {
      object[properties[i]] = value;
    } else {
      if (!object[properties[i]]) {
        object[properties[i]] = {};
      }
      ObjectUtils.set(object[properties[i]], '', value, properties, i + 1);
    }
  };

  public static cloneDeep = (data: unknown, objMap?: WeakMap<any, any>): any => {
    if (!objMap) {
      // Map for handle recursive objects
      objMap = new WeakMap();
    }

    // recursion wrapper
    const deeper = (value: unknown): unknown => {
      if (value && typeof value === 'object') {
        return ObjectUtils.cloneDeep(value, objMap);
      }
      return value;
    };

    // Array value
    if (Array.isArray(data)) return data.map(deeper);

    // Object value
    if (data && typeof data === 'object') {
      // Same object seen earlier
      if (objMap.has(data)) return objMap.get(data);
      // Date object
      if (data instanceof Date) {
        const result = new Date(data.valueOf());
        objMap.set(data, result);
        return result;
      }
      // Use original prototype
      const node = Object.create(Object.getPrototypeOf(data));
      // Save object to map before recursion
      objMap.set(data, node);
      for (const [key, value] of Object.entries(data)) {
        node[key] = deeper(value);
      }
      return node;
    }
    // Scalar value
    return data;
  };

  public static groupBy = <T>(array: T[], key: string, keyBuilder?: (val: T) => string): { [key: string]: T[] } => {
    return array.reduce<{ [key: string]: T[] }>((objectsByKeyValue, obj) => {
      const value = (obj as { [key: string]: unknown })[key];
      const objectKey = keyBuilder ? keyBuilder(obj) : (value as string);
      objectsByKeyValue[objectKey] = (objectsByKeyValue[objectKey] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  };
}
