<ng-container *ngIf="mode === 'date'; else monthPicker">
  <div class="input-container" [class.input-container_primary]="colorTheme === 'primary'">
    <app-icon class="input-container__icon" name="calendar-today-outline-rounded"></app-icon>
    <input
      class="input-container__input"
      type="text"
      readonly="readonly"
      [class.readonly]="readonly"
      [class.input-container_invalid]="control?.touched && control?.dirty && (control?.errors | notEmptyObject)"
      [placeholder]="placeholder"
      [value]="value"
      [min]="minDate"
      [matDatepickerFilter]="dateFilter"
      [matDatepicker]="picker"
      (dateChange)="writeValue($event)"
      (click)="picker.open()" />
  </div>

  <mat-datepicker #picker></mat-datepicker>

  <div
    class="errors"
    *ngIf="control && control.touched && control.dirty && (control.errors | notEmptyObject)">
    <app-error *ngIf="control.errors?.['required']" [error]="'fieldCannotBeEmpty' | translate"></app-error>
  </div>
</ng-container>

<ng-template #monthPicker>
  <div class="input-container" [class.input-container_primary]="colorTheme === 'primary'">
    <app-icon class="input-container__icon" name="calendar-today-outline-rounded"></app-icon>
    <input
      class="input-container__input"
      type="text"
      readonly="readonly"
      [max]="maxDate"
      [min]="minDate"
      [placeholder]="placeholder"
      [value]="value"
      [matDatepicker]="picker"
      (click)="picker.open()" />
    <app-icon *ngIf="value" class="input-container__clear" name="close" (click)="clear()"></app-icon>
  </div>

  <mat-datepicker #picker
                  startView="year"
                  (monthSelected)="selectMonth($event, picker)"></mat-datepicker>
</ng-template>

