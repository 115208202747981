import { Component, HostBinding, Input, OnChanges, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTableClass, DynamicTableComponent, ITableConfig } from '@app/ui-kit/shared/components/tables';
import { ITableData } from '@app/ui-kit/shared/components/tables/table/models/table-data.interface';
import { TableUtils } from '@app/ui-kit/shared/utils';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-data]',
  standalone: true,
  imports: [CommonModule, PipesModule, TranslateModule],
  templateUrl: './dynamic-table-data.component.html',
  styleUrls: ['./dynamic-table-data.component.scss'],
})
export class DynamicTableDataComponent<TableData extends ITableData> implements OnChanges {
  @Input({ required: true }) public config: ITableConfig<TableData>;
  @Input({ required: true }) public data: TableData;
  @Input({ required: false }) public activeField: string;

  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('class.sticky-td') public stickyTdClass = false;
  @HostBinding('class.data-with-action') public dataWithActionClass = false;
  @HostBinding('class.data-with-action_active') public dataWithActionActiveClass = false;
  @HostBinding('class.collapsed') public collapsedClass = false;
  @HostBinding('style') public style: { [p: string]: unknown } | null | undefined;
  @HostBinding('style.left.px') public styleLeftPx: number | undefined;
  @HostBinding('style.width') public width: string | undefined;
  @HostBinding('style.min-width') public minWidth: string | undefined;
  @HostBinding('style.max-width') public maxWidth: string | undefined;

  public getFormattedData = TableUtils.getFormattedData;

  constructor(public dynamicTable: DynamicTableComponent<TableData>) {}

  public ngOnChanges(): void {
    this.classes = this.config.cssClass;
    this.stickyTdClass = !!this.config.sticky;
    this.dataWithActionClass = !!this.config.dialog && !!this.config.dialog(this.data);
    this.dataWithActionActiveClass = !!this.activeField && this.activeField === this.config.fieldName + this.data.id;
    this.style = TableUtils.getStylesConfig(this.data, this.config);
    this.styleLeftPx = this.config.sticky?.left;
    if ('width' in this.config) {
      this.minWidth = this.config.width;
      this.maxWidth = this.config.width;
    }
  }

  public getColumnTemplate = (
    data: TableData,
    template: TemplateRef<unknown> | ((data: TableData) => TemplateRef<unknown> | undefined)
  ): TemplateRef<unknown> | undefined => {
    return typeof template === 'function' ? template(data) : template;
  };
}
