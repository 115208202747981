import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { ConfirmationDialogComponent } from '@app/core/components/confirmation-dialog/confirmation-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { IConfirmationDialogData } from '@app/core/models/interfaces/confirmation-dialog-data.interface';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(private dialog: Dialog, private overlay: Overlay) {}

  public confirm(data?: IConfirmationDialogData): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      backdropClass: 'cdk-overlay-backdrop-with-blur',
      disableClose: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        title: data?.title ?? '',
        text: data?.text ?? '',
        okButtonText: data?.okButtonText ?? 'confirm',
        cancelButtonText: data?.cancelButtonText ?? 'cancel',
        width: data?.width ?? 300,
      },
    });
    return dialog.closed as Observable<boolean>;
  }
}
