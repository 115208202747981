import { Component } from '@angular/core';
import { DragToScrollDirective } from '@app/ui-kit/shared/directives';

@Component({
  selector: 'app-table-layout-content-scrollable',
  standalone: true,
  templateUrl: './table-layout-content-scrollable.component.html',
  styleUrls: ['./table-layout-content-scrollable.component.scss'],
  hostDirectives: [DragToScrollDirective],
})
export class TableLayoutContentScrollableComponent {}
