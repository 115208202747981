import { Component, HostBinding, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTableClass, DynamicTableComponent, ITableConfig } from '@app/ui-kit/shared/components/tables';
import { ITableData } from '@app/ui-kit/shared/components/tables/table/models/table-data.interface';
import { ObjectUtils, TableUtils } from '@app/ui-kit/shared/utils';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-inner-data]',
  standalone: true,
  imports: [CommonModule, PipesModule, TranslateModule],
  templateUrl: './dynamic-table-inner-data.component.html',
  styleUrls: ['./dynamic-table-inner-data.component.scss'],
})
export class DynamicTableInnerDataComponent<TableData extends ITableData> implements OnInit, OnChanges {
  public innerDataConfig: ITableConfig<TableData>;

  @Input({ required: true }) public config: ITableConfig<TableData>;
  @Input({ required: true }) public set innerConfig(innerConfig: ITableConfig<TableData>) {
    this.innerDataConfig = innerConfig;
    this.classes = this.innerDataConfig.cssClass;
    if ('width' in this.innerDataConfig) {
      this.minWidth = this.innerDataConfig.width;
      this.maxWidth = this.innerDataConfig.width;
    }
  }
  @Input({ required: true }) public data: TableData;
  @Input({ required: false }) public activeField: string;

  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('class.data-with-action') public dataWithActionClass = false;
  @HostBinding('class.data-with-action_active') public dataWithActionActiveClass = false;
  @HostBinding('style') public style: { [p: string]: unknown } | null | undefined;
  @HostBinding('style.width') public width: string | undefined;
  @HostBinding('style.min-width') public minWidth: string | undefined;
  @HostBinding('style.max-width') public maxWidth: string | undefined;

  public getFormattedData = TableUtils.getFormattedData;
  public fieldName: string;

  constructor(public dynamicTable: DynamicTableComponent<TableData>) {}

  public ngOnInit(): void {
    this.fieldName = this.resolveFieldName(this.config, this.innerDataConfig);
  }

  public ngOnChanges(): void {
    this.dataWithActionClass = !!this.innerDataConfig.dialog && !!this.innerDataConfig.dialog(this.data);
    this.dataWithActionActiveClass = this.activeField === this.fieldName + this.data.id;
    this.style =
      (this.config.stylesConfig || this.innerDataConfig.stylesConfig) &&
      TableUtils.getStylesConfig(this.data, this.config, this.fieldName);
  }

  public resolveFieldName = (column: ITableConfig<TableData>, innerColumn?: ITableConfig<TableData>): string => {
    return !innerColumn ? column.fieldName : (column.fieldName ? column.fieldName + '.' : '') + innerColumn.fieldName;
  };

  public getColumnTemplate = (
    data: TableData,
    template: TemplateRef<unknown> | ((data: TableData) => TemplateRef<unknown> | undefined)
  ): TemplateRef<unknown> | undefined => {
    return typeof template === 'function' ? template(data) : template;
  };

  public resolveFieldData = (data: TableData, field: string): string | number | null => {
    return ObjectUtils.resolveFieldData(data, field);
  };
}
