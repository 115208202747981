import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTableClass, ITableConfig } from '@app/ui-kit/shared/components/tables';
import { ColumnFilterComponent } from '@app/ui-kit/shared/components/tables/table/components/column-filter/column-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { ITableData } from '@app/ui-kit/shared/components/tables/table/models/table-data.interface';
import { DynamicTableService } from '@app/ui-kit/shared/components/tables/dynamic-table/dynamic-table.service';
import { ColumnCollapseComponent } from '@app/ui-kit/shared/components/tables/dynamic-table/components/column-collapse/column-collapse.component';
import { IsArrayPipe } from '@app/ui-kit/shared/pipes';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-dynamic-table-header-collapsible]',
  standalone: true,
  imports: [CommonModule, ColumnFilterComponent, TranslateModule, ColumnCollapseComponent, IsArrayPipe],
  templateUrl: './dynamic-table-header-collapsible.component.html',
  styleUrls: ['./dynamic-table-header-collapsible.component.scss'],
})
export class DynamicTableHeaderCollapsibleComponent<TableData extends ITableData> implements OnChanges {
  private _isCollapsed = false;
  @Input({ required: true }) public set isCollapsed(isCollapsed: boolean) {
    this._isCollapsed = isCollapsed;
    if (this.isCollapsed) {
      this.dynamicTableService.onCollapseColumn(this.config.collapsible!, false);
    }
  }
  public get isCollapsed(): boolean {
    return this._isCollapsed;
    // update collapsed when table data switched
  }

  @Input({ required: true }) public config: ITableConfig<TableData>;
  @Input({ required: true }) public hasInnerColumns: boolean;
  @Input({ required: false }) public fieldForFilter: string | undefined;

  @HostBinding('class') public classes: DynamicTableClass | DynamicTableClass[] | undefined;
  @HostBinding('class.sticky-th') public stickyThClass = false;
  @HostBinding('class.empty-column') public emptyColumnClass = false;
  @HostBinding('attr.colspan') public colspan: string | number | undefined;
  @HostBinding('attr.rowspan') public rowspan: string | number | undefined;
  @HostBinding('style.left.px') public styleLeftPx: number | undefined;
  @HostBinding('style.min-width') public minWidth: string | undefined;
  @HostBinding('style.max-width') public maxWidth: string | undefined;

  constructor(private dynamicTableService: DynamicTableService) {}

  public ngOnChanges(): void {
    this.classes = this.config.cssClass;
    this.stickyThClass = !!this.config.sticky;
    this.emptyColumnClass = this.isCollapsed;
    this.colspan = this.isCollapsed ? 1 : this.hasInnerColumns ? this.config.columns?.length : this.config.colspan;
    this.rowspan = this.config.rowspan;
    this.styleLeftPx = this.config.sticky?.left;
    this.minWidth = this.config.width;
    this.maxWidth = this.config.width;
  }

  public switch(): void {
    if (this.isCollapsed) {
      this.dynamicTableService.onExpandColumn(this.config.collapsible!);
    } else {
      this.dynamicTableService.onCollapseColumn(this.config.collapsible!);
    }
  }
}
