<label class="checkbox-container">
  <input
    class="checkbox-input"
    type="checkbox"
    [ngModel]="checked"
    (ngModelChange)="onModelChange($event)"
  />
  <div class="checkbox"
       [class.checkbox_checked]="checked"
       [class.checkbox_small]="isSmall"
       [class.checkbox_large]="isLarge">
    <app-icon class="checkbox__filling" name="check-outlined" *ngIf="checked"></app-icon>
  </div>
</label>
