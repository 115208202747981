<div class="container">
  <app-select class="page-size" [options]="pageSizes" [formControl]="pageSizeControl" [disableClear]="true"></app-select>
  <div class="paginator">
    <div class="paginator__button" [class.disabled]="value.page === 1" (click)="selectPage(value.page - 1)">
      <app-icon name="arrow-back-new-rounded-left"></app-icon>
    </div>
    <div class="paginator__pages">
      <ng-container *ngIf="visiblePages[0] !== 1">
        <div
          class="paginator__page"
          (click)="selectPage(1)">1</div>
        <div class="paginator__page">...</div>
      </ng-container>
      <div
        class="paginator__page"
        (click)="selectPage(page)"
        [class.paginator__page-active]="value.page === page"
        *ngFor="let page of visiblePages; let i = index">{{page}}</div>
      <ng-container *ngIf="visiblePages[visiblePages.length - 1] !== totalPages">
        <div class="paginator__page">...</div>
        <div
          class="paginator__page"
          (click)="selectPage(totalPages)">{{totalPages}}</div>
      </ng-container>
    </div>
    <div
      class="paginator__button paginator__button-next"
      [class.disabled]="value.page === totalPages"
      (click)="selectPage(value.page + 1)">
      <app-icon name="arrow-back-new-rounded-left"></app-icon>
    </div>
  </div>
</div>
