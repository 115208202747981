import { Injectable } from '@angular/core';
import { IGlobalFiltersState } from '@app/core/models/interfaces/global-filters-state.interface';
import { Division } from '@app/core/domain';

@Injectable({ providedIn: 'root' })
export class GlobalFiltersService {
  private readonly STORAGE_KEY = 'filters';
  private _state: IGlobalFiltersState;

  public get state(): IGlobalFiltersState {
    return this._state;
  }

  private set state(state: IGlobalFiltersState) {
    this._state = state;
  }

  constructor() {
    this.initStateFromLocalStorage();
  }

  public getDivisionsState(): Division | undefined {
    return this.state.division;
  }

  public setDivisionState(division: Division | undefined): void {
    this._state.division = division;
    this.updateStateInLocalStorage();
  }

  public resetState(): void {
    this._state.division = undefined;
    this.updateStateInLocalStorage();
  }

  private initStateFromLocalStorage(): void {
    const storageData = window.localStorage.getItem(this.STORAGE_KEY);
    const defaultState: IGlobalFiltersState = { division: undefined };
    if (storageData) {
      try {
        this.state = JSON.parse(storageData) as IGlobalFiltersState;
      } catch {
        this.state = defaultState;
      }
    } else {
      this.state = defaultState;
    }
  }

  private updateStateInLocalStorage(): void {
    window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.state));
  }
}
