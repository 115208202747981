import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { LogoutDialogComponent } from '@app/ui-kit/layouts/main/dialogs/logout-dialog/logout-dialog.component';
import { LayoutService, MenuService, MenuStorageService } from '@app/core/services';
import { MobileLogoutDialogComponent } from '@app/ui-kit/layouts/main/dialogs/mobile-logout-dialog/mobile-logout-dialog.component';
import { Observable, Subject, tap } from 'rxjs';
import { IMenuData } from '@app/core/models/interfaces/menu-data.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() public mode: 'full' | 'minimal' = 'full';
  @Output() public menuNavigation = new EventEmitter<void>();
  public sections = this.menuService.getMenu();
  public openedMenuItems: string[] = this.menuData.openedMenuItems;
  public collapsed = false;
  public isDesktop$: Observable<boolean>;
  private destroy$ = new Subject<void>();
  private isDesktop = false;

  get menuData(): IMenuData {
    return this.menuStorageService.menuData;
  }

  constructor(
    private dialog: Dialog,
    private layoutService: LayoutService,
    private menuService: MenuService,
    private menuStorageService: MenuStorageService
  ) {}

  public ngOnInit(): void {
    this.updateCollapsed(this.menuData.isCollapsed);
    this.isDesktop$ = this.layoutService.isDesktop$.pipe(
      tap((isDesktop) => {
        this.isDesktop = isDesktop;
        if (!isDesktop) {
          this.updateCollapsed(false);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onMenuNavigation(): void {
    this.menuNavigation.emit();
  }

  public switchCollapsed(): void {
    this.updateCollapsed(!this.collapsed);
    this.menuStorageService.setIsMenuCollapsed(this.collapsed);
  }

  public runExternalUrlOnClick(event: Event, externalUrlFunc: () => void): void {
    event.preventDefault();
    externalUrlFunc();
  }

  public logout(): void {
    this.isDesktop
      ? this.dialog.open(LogoutDialogComponent, { backdropClass: 'cdk-overlay-backdrop-with-blur' })
      : this.dialog.open(MobileLogoutDialogComponent, {
          panelClass: 'mobile-panel',
          backdropClass: 'cdk-overlay-backdrop-with-blur',
        });
  }

  public switchMenuItem(name: string): void {
    if (this.openedMenuItems.includes(name)) {
      this.openedMenuItems = this.openedMenuItems.filter((itemName) => itemName !== name);
    } else {
      this.openedMenuItems = [...this.openedMenuItems, name];
    }
    this.menuStorageService.setOpenedMenuItems(this.openedMenuItems);
  }

  public isOpened = (name: string, openedMenuItems: string[]): boolean => {
    return openedMenuItems.includes(name);
  };

  private updateCollapsed(isCollapsed: boolean): void {
    this.collapsed = isCollapsed;
    this.layoutService.menuCollapsed(this.collapsed);
  }
}
