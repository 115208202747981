import { DivisionType, SubDivisionType } from '@app/core/domain';

export interface IDivisionResponse {
  id: number;
  name: string;
  address: string;
  type: DivisionType;
  subDivisions?: ISubDivisionResponse[];
}

export interface ISubDivisionResponse {
  id: number;
  name: string;
  type: SubDivisionType;
  active: boolean;
}
