import { MatDateFormats } from '@angular/material/core';

export const CUSTOM_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dd MMMM yyyy',
    monthYearLabel: 'LLL yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'LLLL YYYY',
  },
};
