<div class="year-picker">
  <div class="year-picker__navigation year-picker__prev" (click)="toPrevYear()">
    <app-icon name="arrow-back-new-rounded-left"></app-icon>
  </div>
  <input
    class="year-picker__value"
    [formControl]="date"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    readonly="readonly" />
  <div class="year-picker__navigation year-picker__next" (click)="toNextYear()">
    <app-icon name="arrow-back-new-rounded-left"></app-icon>
  </div>

  <div class="year-picker__calendar-trigger" (click)="picker.open()">
    <app-icon name="calendar-today-outline-rounded"></app-icon>
  </div>

  <mat-datepicker #picker startView="multi-year" (yearSelected)="selectYear($event, picker)"></mat-datepicker>
</div>
