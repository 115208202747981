import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppLanguage, LanguageService, LayoutService } from '@app/core/services';
import { map, Observable, tap } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Dialog, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { LanguageDialogComponent } from '@app/core/components/select-language/language-dialog/language-dialog.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IconComponent, NgForOf, NgIf, DialogModule],
})
export class SelectLanguageComponent implements OnInit {
  public currentLanguage$ = this.languageService.currentLanguage$.pipe(
    map((lang) => (lang === AppLanguage.Ukrainian ? 'ua' : lang))
  );
  public languages = Object.values(AppLanguage) as AppLanguage[];
  public isDesktop$: Observable<boolean>;
  private dialogRef: DialogRef<unknown, LanguageDialogComponent> | undefined;

  constructor(
    private languageService: LanguageService,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private cdk: ChangeDetectorRef,
    private dialog: Dialog
  ) {}

  public ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$.pipe(
      tap((isDesktop) => {
        if (isDesktop && this.dialogRef) {
          this.dialogRef.close();
        }
        this.cdk.detectChanges();
      })
    );
  }

  public switchLanguage(language: AppLanguage): void {
    if (language === this.languageService.getCurrentLanguage()) {
      return;
    }
    this.languageService.switchLanguage(language);
  }

  public openLanguageDialog(): void {
    this.dialogRef = this.dialog.open(LanguageDialogComponent, {
      panelClass: 'mobile-panel',
      backdropClass: 'cdk-overlay-backdrop-with-blur',
    });
  }
}
