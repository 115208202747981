import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from '@app/core/services';
import { NotificationComponent } from '@app/ui-kit/shared/components/indicators/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly NOTIFICATION_DURATION = 5000;

  constructor(private snackBar: MatSnackBar, private languageService: LanguageService) {}

  public success(message: string): void {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: { message: this.languageService.translate(message), status: 'success' },
      duration: this.NOTIFICATION_DURATION,
    });
  }

  public error(message: string, autoClose = true): void {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: { message: this.languageService.translate(message), status: 'error' },
      duration: autoClose ? this.NOTIFICATION_DURATION : undefined,
    });
  }
}
