import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { AuthenticationService } from '@app/core/services';

@Component({
  selector: 'app-mobile-logout-dialog',
  templateUrl: './mobile-logout-dialog.component.html',
  styleUrls: ['./mobile-logout-dialog.component.scss'],
})
export class MobileLogoutDialogComponent {
  constructor(private dialogRef: DialogRef, private authService: AuthenticationService) {}

  public logout(): void {
    this.dialogRef.close();
    this.authService.logout();
  }
}
