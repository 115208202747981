import { DynamicTableClass } from '@app/ui-kit/shared/components/tables';

export interface IDynamicTableCollapsibleConfig {
  id: string;
  fromColumn?: number;
  toColumn?: number;
  position: 'additionalHeader' | 'header';
  collapsedByDefault?: boolean;
  totalLabelPrefix?: string;
  totalLabel?: string | number;
  cssClass?: DynamicTableClass | DynamicTableClass[];
}
