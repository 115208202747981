import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IConfirmationDialogData } from '@app/core/models/interfaces/confirmation-dialog-data.interface';
import { InlineDialogComponent } from '@app/ui-kit/shared/components/dialogs';
import { ActionButtonComponent } from '@app/ui-kit/shared/components/controls';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [InlineDialogComponent, ActionButtonComponent, TranslateModule, IconComponent],
})
export class ConfirmationDialogComponent {
  constructor(@Inject(DIALOG_DATA) public data: IConfirmationDialogData, private dialogRef: DialogRef) {}

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
