import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appDragToScroll]',
  standalone: true,
})
export class DragToScrollDirective implements OnDestroy {
  private element = this.elementRef.nativeElement;
  private position = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };
  private mouseUpEvent$ = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  public ngOnDestroy(): void {
    this.mouseUpEvent$.next();
    this.mouseUpEvent$.complete();
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent): void {
    this.element.style.cursor = 'grabbing';
    this.element.style.userSelect = 'none';
    this.position = {
      scrollTop: this.element.scrollTop,
      scrollLeft: this.element.scrollLeft,
      mouseX: event.clientX,
      mouseY: event.clientY,
    };

    fromEvent(document, 'mousemove', this.mouseMoveHandler).pipe(takeUntil(this.mouseUpEvent$)).subscribe();
    fromEvent(document, 'mouseup', this.mouseUpHandler).pipe(takeUntil(this.mouseUpEvent$)).subscribe();
  }

  private mouseMoveHandler = (event: MouseEvent): void => {
    // How far the mouse has been moved
    const dx = event.clientX - this.position.mouseX;
    const dy = event.clientY - this.position.mouseY;
    this.element.scrollTop = this.position.scrollTop - dy;
    this.element.scrollLeft = this.position.scrollLeft - dx;
  };

  private mouseUpHandler = (): void => {
    this.element.style.cursor = 'default';
    this.element.style.removeProperty('user-select');
    this.mouseUpEvent$.next();
  };
}
