import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appFadeOutOnScroll]',
  standalone: true,
})
export class FadeOutOnScrollDirective implements OnInit, OnDestroy {
  @Input() showHorizontalFadeOut = true;
  @Input() showVerticalFadeOut = false;
  @Input() defaultColor: 'blue' | 'white' = 'blue';
  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    if (this.showHorizontalFadeOut) {
      this.addHorizontalFadeOut();
    }
    if (this.showVerticalFadeOut) {
      this.addVerticalFadeOut();
    }
  }

  private addHorizontalFadeOut(): void {
    const element = this.elementRef.nativeElement;
    const fadeOutElement = document.createElement('div');
    fadeOutElement.className = this.defaultColor === 'blue' ? 'fade-out-on-scroll' : 'fade-out-on-scroll light';
    element.appendChild(fadeOutElement);

    fromEvent(element, 'scroll', () => {
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        fadeOutElement.style.visibility = 'hidden';
      } else {
        fadeOutElement.style.bottom = `-${element.scrollTop}px`;
        fadeOutElement.style.left = `${element.scrollLeft}px`;
        fadeOutElement.style.visibility = 'visible';
      }
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private addVerticalFadeOut(): void {
    const element = this.elementRef.nativeElement;
    const fadeOutElement = document.createElement('div');
    fadeOutElement.className =
      this.defaultColor === 'blue' ? 'fade-out-on-scroll-vertical' : 'fade-out-on-scroll-vertical light';
    element.appendChild(fadeOutElement);

    fromEvent(element, 'scroll', () => {
      fadeOutElement.style.top = `${element.scrollTop}px`;
      fadeOutElement.style.right = `-${element.scrollLeft}px`;
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
