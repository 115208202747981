<ng-container *ngIf="config.template && (data | displayFn: getColumnTemplate: config.template) as template; else dataCell">
  <ng-container *ngTemplateOutlet="template;
                             context: {
                             data,
                             fieldName: config.fieldName,
                             value: data | displayFn : getFormattedData : config : null : dynamicTable.dataUpdate}">
  </ng-container>
</ng-container>
<ng-template #dataCell>
  <div class="data">
    <ng-container *ngTemplateOutlet="columnTemplate; context: {config, data}"></ng-container>
  </div>
</ng-template>

<ng-template #columnTemplate let-column="config" let-data="data">
  <span class="data__prefix" *ngIf="column.dataPrefix">
    {{ column.dataPrefix | translate }}
  </span>
  <span class="data__data" *ngIf="!column.translatable; else translatable">
    {{ data | displayFn : getFormattedData : column : null : dynamicTable.dataUpdate }}
  </span>
  <ng-template #translatable>
      <span class="data__data">
    {{ data | displayFn : getFormattedData : column : null : dynamicTable.dataUpdate | translate }}
  </span>
  </ng-template>
</ng-template>
