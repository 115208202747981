import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService implements OnDestroy {
  private _isMenuCollapsed$ = new BehaviorSubject<boolean | null>(null);
  private _isDesktop$ = new BehaviorSubject<boolean | null>(null);
  private _isMobile$ = new BehaviorSubject<boolean | null>(null);
  public isMenuCollapsed$: Observable<boolean> = this._isMenuCollapsed$
    .asObservable()
    .pipe(filter((val) => val !== null)) as Observable<boolean>;
  public isMobile$: Observable<boolean> = this._isMobile$
    .asObservable()
    .pipe(filter((val) => val !== null)) as Observable<boolean>;
  public isDesktop$: Observable<boolean> = this._isDesktop$
    .asObservable()
    .pipe(filter((val) => val !== null)) as Observable<boolean>;
  private readonly DESKTOP_BREAKPOINT = '(min-width: 1024px)';
  private destroy$ = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([this.DESKTOP_BREAKPOINT])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (state: BreakpointState) => {
          this._isDesktop$.next(state.matches);
          this._isMobile$.next(!state.matches);
        },
      });
  }

  public menuCollapsed(state: boolean): void {
    this._isMenuCollapsed$.next(state);
  }

  public get isDesktop(): boolean | null {
    return this._isDesktop$.getValue();
  }

  public get isMobile(): boolean | null {
    return this._isMobile$.getValue();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
