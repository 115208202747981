import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService, LanguageService } from '@app/core/services';
import { IValidationError } from '@app/core/models/interfaces/response-errors.interface';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private languageService: LanguageService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error.status === 401) {
          this.authService.logout();
        } else if (error.status === 400) {
          const validationError = error.error as IValidationError;
          const fieldError = validationError.fieldErrors?.[0];
          const message = fieldError
            ? `${this.languageService.translate('field')} "${this.languageService.translate(fieldError.fieldName)}" ${
                fieldError.errorMessage
              }`
            : validationError.message;
          return throwError(() => {
            return { code: validationError.code, message };
          });
        }
        return throwError(() => error.error);
      })
    );
  }
}
