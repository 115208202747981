import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { User, UserRepo } from '@app/core/domain';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private userRepo: UserRepo) {}

  public getAll(): Observable<User[]> {
    return this.userRepo.getAll().pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  public editIsTraceable(userId: number, traceable: boolean): Observable<void> {
    return this.userRepo.editIsTraceable(userId, traceable).pipe();
  }
}
