import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input({ required: false }) public checked = false;
  @Input({ required: false }) isSmall = false;
  @Input({ required: false }) isLarge = false;

  public onChange: (val: boolean) => undefined = () => undefined;
  public onTouch: () => undefined = () => undefined;

  public registerOnChange(fn: (val: boolean) => undefined): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => undefined): void {
    this.onTouch = fn;
  }

  public writeValue(checked: boolean): void {
    this.checked = checked;
  }

  public onModelChange(e: boolean): void {
    this.checked = e;
    this.onChange(e);
  }
}
