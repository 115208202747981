import { BehaviorSubject, filter } from 'rxjs';

export enum AppLanguage {
  Ukrainian = 'uk',
  Russian = 'ru',
}

export enum Locale {
  Ukrainian = 'uk-UA',
  Russian = 'ru-RU',
}

export abstract class LanguageService {
  private _currentLanguage = new BehaviorSubject<AppLanguage | undefined>(undefined);
  protected set currentLanguage(language: AppLanguage | undefined) {
    this._currentLanguage.next(language);
  }
  protected get currentLanguage(): AppLanguage | undefined {
    return this._currentLanguage.value;
  }

  public currentLanguage$ = this._currentLanguage.asObservable().pipe(filter(Boolean));

  public getCurrentLanguage(): AppLanguage | undefined {
    return this.currentLanguage;
  }
  public abstract setDefaultLanguage(language: AppLanguage): void;
  public abstract switchLanguage(language: AppLanguage): void;
  public abstract translate(text: string): string;
}
