import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services';

export const notAuthorizedGuard = (): boolean => {
  const auth = inject(AuthenticationService);
  const router = inject(Router);
  if (auth.isAuthenticated()) {
    router.navigate(['/']);
    return false;
  }
  return true;
};
