import { CommentConfirmationType, CommentType } from '@app/core/domain';

export interface ICommentsResponse {
  historyComment: ICommentResponse[];
}

export interface ICommentResponse {
  id: number;
  comment: string;
  amount: number;
  commentType: CommentType;
  commentDate: string;
  confirmationDate: string;
  confirmationType: CommentConfirmationType;
}
