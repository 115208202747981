import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { User, UserRepo } from '@app/core/domain';
import { IUserResponse, buildUser } from '@app/core/api';

@Injectable({ providedIn: 'root' })
export class UserRepoImpl implements UserRepo {
  private readonly BASE_API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAll(): Observable<User[]> {
    return this.http.get<IUserResponse[]>(`${this.BASE_API_URL}/users`).pipe(map((items) => items.map(buildUser)));
  }

  public editIsTraceable(userId: number, traceable: boolean): Observable<void> {
    return this.http.put<void>(`${this.BASE_API_URL}/users/${userId}/traceable/${traceable}`, {});
  }
}
