import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notEmptyObject',
})
export class NotEmptyObjectPipe implements PipeTransform {
  public transform(obj: unknown): boolean {
    return !!obj && Object.keys(obj).length !== 0;
  }
}
