import { ITableDataGroupHeaderConfig } from '@app/ui-kit/shared/components/tables';

export interface ITableDataRow<TableData> {
  type: TableRowType.Data;
  dataGroupIndex: number;
  dataIndex: number;
  data: TableData;
}

export interface ITableDataGroupHeaderRow {
  type: TableRowType.DataGroupHeader;
  header: ITableDataGroupHeaderConfig[];
  dataGroupIndex: number;
  dataGroupLength: number;
}

export interface ITableDataGroupSubheaderRow {
  type: TableRowType.DataGroupSubheader;
  header: ITableDataGroupHeaderConfig[];
  dataGroupIndex: number;
}

export enum TableRowType {
  Data = 'Data',
  DataGroupHeader = 'DataGroupHeader',
  DataGroupSubheader = 'DataGroupSubheader',
}
