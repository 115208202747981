import { DateTime, Interval } from 'luxon';

export class DateUtils {
  public static formatDateToMonthAndYear(val: string | number | boolean): string {
    return DateTime.fromISO(val + '').toFormat('dd MMMM yyyy');
  }

  public static getMonthAndYearFromDate(date: string): { year: number; month: number } {
    let year = DateTime.now().year;
    let month = DateTime.now().month;
    if (date) {
      const dateTime = DateTime.fromSQL(date);
      year = dateTime.year;
      month = dateTime.month;
    }
    return { year, month };
  }

  public static checkIfDateInCurrentMonth(dateValue: string | null): boolean {
    if (!dateValue) return false;
    const date = DateTime.fromSQL(dateValue);
    const now = DateTime.now();
    return date.year === now.year && date.month === now.month;
  }

  public static checkIfDateTodayOrInFuture(dateValue: string | DateTime | null | undefined): boolean {
    if (!dateValue) return false;
    const date = typeof dateValue === 'string' ? DateTime.fromSQL(dateValue) : dateValue;
    return date.startOf('day') >= DateTime.now().startOf('day');
  }

  public static checkIfDateInFuture(dateValue: string | DateTime | null | undefined): boolean {
    if (!dateValue) return false;
    const date = typeof dateValue === 'string' ? DateTime.fromSQL(dateValue) : dateValue;
    return date.startOf('day') > DateTime.now().startOf('day');
  }

  public static isToday(date: DateTime | undefined): boolean {
    return date?.hasSame(DateTime.now(), 'day') ?? false;
  }

  public static isDateInCurrentMonth(date: DateTime): boolean {
    return DateTime.now().month === date.month;
  }

  public static isWeekday(date: DateTime): boolean {
    return !DateUtils.isWeekend(date);
  }

  public static isWeekend(date: DateTime): boolean {
    return date.weekday === 6 || date.weekday === 7;
  }

  public static getFirstMonthInterval(date: DateTime): Interval {
    return Interval.fromDateTimes(date.startOf('month'), date.startOf('month').plus({ day: 15 }));
  }

  public static getSecondMonthInterval(date: DateTime): Interval {
    return Interval.fromDateTimes(date.startOf('month').plus({ day: 15 }), date.endOf('month'));
  }

  public static getMonthInterval(date: DateTime): Interval {
    return Interval.fromDateTimes(date.startOf('month'), date.endOf('month'));
  }

  public static getYearInterval(date: DateTime): Interval {
    return Interval.fromDateTimes(date.startOf('year'), date.endOf('year'));
  }
}
