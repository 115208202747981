import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';

@Component({
  selector: 'app-store-info',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent {
  @Input() public store: { id: number | undefined; name: string | undefined; address: string | undefined } | null;
}
