<div class="container" [style.background-color]="fullscreenClass ? backgroundColor : null">
  <div class="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
