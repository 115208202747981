import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-update-pwa-dialog',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule],
  templateUrl: './update-pwa-dialog.component.html',
  styleUrls: ['./update-pwa-dialog.component.scss'],
})
export class UpdatePwaDialogComponent {
  constructor(private dialogRef: DialogRef) {}

  public close(): void {
    this.dialogRef.close();
  }

  public update(): void {
    window.location.reload();
  }
}
