import { Component, Input } from '@angular/core';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { SlideToggleComponent } from '@app/ui-kit/shared/components/controls/slide-toggle/slide-toggle.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogRef } from '@angular/cdk/dialog';
import {style} from "@angular/animations";

@Component({
  selector: 'app-inline-dialog',
  standalone: true,
  imports: [IconComponent, SlideToggleComponent, TranslateModule],
  templateUrl: './inline-dialog.component.html',
  styleUrls: ['./inline-dialog.component.scss'],
})
export class InlineDialogComponent {
  @Input() public width: string | null = '282px';
  @Input() public height = '';
  @Input() public header = '';

  constructor(private dialogRef: DialogRef) {}

  public close(): void {
    this.dialogRef.close();
  }

  protected readonly style = style;
}
