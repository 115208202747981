import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/ui-kit/shared/components/indicators/icon/icon.component';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Icon } from '@app/ui-kit/shared/components/indicators/icon/icons';
import { PipesModule } from '@app/ui-kit/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { HasControl } from '@app/ui-kit/shared/models/has-control';
import { ErrorComponent } from '@app/ui-kit/shared/components/indicators/error/error.component';

@Component({
  selector: 'app-action-input',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    NgxMaskDirective,
    ErrorComponent,
  ],
  templateUrl: './action-input.component.html',
  styleUrls: ['./action-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ActionInputComponent,
      multi: true,
    },
    provideNgxMask(),
  ],
})
export class ActionInputComponent extends HasControl implements ControlValueAccessor, OnInit {
  @Input({ required: false }) public icon: Icon | null = null;
  @Input({ required: false }) public placeholder = '';
  @Input({ required: false }) public mask: string | undefined;
  @Input({ required: false }) public suffix: string | undefined;
  @Input({ required: false }) public readonly = false;
  @Input({ required: false }) public showErrors = true;
  @Output() public typeEnterOrBlur = new EventEmitter<string>();
  private _value = '';
  public set value(value: string) {
    this._value = value;
    this.onChange(value);
  }
  public get value(): string {
    return this._value;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    this.setComponentControl();
  }

  public onChange: (val: string) => undefined = () => undefined;
  public onTouch: (val: string) => undefined = () => undefined;

  public writeValue(value: string): void {
    this.value = value;
    this.onTouch(value);
  }

  public registerOnChange(fn: (val: string) => undefined): void {
    this.onChange = fn;
  }

  public registerOnTouched(onTouched: (val: string) => undefined): void {
    this.onTouch = onTouched;
  }

  public clear(): void {
    if (this.readonly) {
      return;
    }
    this.writeValue('');
    this.onTypeEnterOrBlur();
  }

  public onBlur(): void {
    this.onTouch(this.value);
    this.onTypeEnterOrBlur();
  }

  public onTypeEnterOrBlur(): void {
    this.typeEnterOrBlur.emit(this.value);
  }
}
