import { AppLanguage, Locale } from '@app/core/services';

export class LanguageUtils {
  public static getLocale(lang: AppLanguage): Locale {
    return lang === AppLanguage.Ukrainian ? Locale.Ukrainian : Locale.Russian;
  }

  public static getDayOfWeekNames(lang: AppLanguage): () => string[] {
    return () => [lang === AppLanguage.Ukrainian ? 'Нд' : 'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  }
}
