import { DynamicTableClass } from '@app/ui-kit/shared/components/tables';
import { TemplateRef } from '@angular/core';

export interface ITableDataGroupHeader {
  [dataGroupIndex: number]: ITableDataGroupHeaderConfig[] | undefined;
}

export interface ITableDataGroupHeaderConfig {
  name: string;
  colspan?: number | string;
  cssClass?: DynamicTableClass | DynamicTableClass[];
  indexCssClass?: DynamicTableClass | DynamicTableClass[];
  stylesConfig?: { [p: string]: unknown };
  width?: string;
  sticky?: { left: number };
  collapsibleVertically?: boolean; // apply to first column
  template?: TemplateRef<unknown>;
  subheaders?: ITableDataGroupSubHeader;
}

export interface ITableDataGroupSubHeader {
  [rowIndex: number]: ITableDataGroupHeaderConfig[] | undefined;
}
