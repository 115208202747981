import { Injectable } from '@angular/core';
import { Comment, CommentRepo, CommentType } from '@app/core/domain';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { buildComment, ICommentResponse, ICommentsResponse } from '@app/core/api';

@Injectable({ providedIn: 'root' })
export class CommentRepoImpl implements CommentRepo {
  private readonly BASE_API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public getAll(userId: number, type?: CommentType): Observable<Comment[]> {
    return this.http
      .get<ICommentsResponse>(`${this.BASE_API_URL}/users/${userId}/comments${type ? '/' + type : ''}`)
      .pipe(map((res) => res.historyComment.map(buildComment)));
  }

  public edit(comment: Comment): Observable<Comment> {
    return this.http.post<ICommentResponse>(`${this.BASE_API_URL}/comment`, comment).pipe(map(buildComment));
  }

  public confirm(comment: Comment): Observable<Comment> {
    return this.http.post<ICommentResponse>(`${this.BASE_API_URL}/hr/comment`, comment).pipe(map(buildComment));
  }

  public delete(commentId: number): Observable<void> {
    return this.http.delete<void>(`${this.BASE_API_URL}/comment/${commentId}`);
  }
}
