export type DynamicTableClass =
  | 'small'
  | 'medium'
  | 'medium-large'
  | 'large'
  | 'auto'
  | 'accent'
  | 'accent-2'
  | 'accent-3'
  | 'light'
  | 'important'
  | 'text-align-left'
  | 'red'
  | 'red-dark'
  | 'light-font'
  | 'capitalize'
  | 'bold-font'
  | 'main-title-color'
  | 'extra-large'
  | 'red-font'
  | 'red-border-1'
  | 'red-border-2';
