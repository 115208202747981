export class TimeTracking {
  trackingOnline: boolean;
  trackingRequired: boolean;
  valid: boolean;
  lastTrackUpdateDate?: string;
  targetLocationId?: number;
  targetLocationTitle?: string;
  tracingLocationId?: number;
  tracingLocationTitle?: string;

  public isStatusOk(): boolean {
    return this.valid;
  }

  public getErrorMessage(): string | null {
    if (!this.trackingOnline) {
      return 'geoTrackingOffError';
    } else if (this.targetLocationId && this.targetLocationId !== this.tracingLocationId) {
      return 'geoTrackingWrongLocationError';
    } else if (!this.targetLocationId) {
      return 'geoTrackingOutOfLocationError';
    } else {
      return null;
    }
  }
}
