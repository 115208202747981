import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled, tap } from 'rxjs';
import { Division, DivisionRepo, SubDivision } from '@app/core/domain';

@Injectable({ providedIn: 'root' })
export class DivisionService {
  private subDivisionsCache = new Map<number, SubDivision[]>();

  constructor(private divisionRepo: DivisionRepo) {}

  public getAll(): Observable<Division[]> {
    return this.divisionRepo.getAll();
  }

  public getSubDivisions(topDivId: number): Observable<SubDivision[]> {
    if (this.subDivisionsCache.has(topDivId)) {
      return scheduled([this.subDivisionsCache.get(topDivId)!], asyncScheduler);
    } else {
      return this.divisionRepo.getSubDivisions(topDivId).pipe(
        tap((subDivisions) => {
          this.subDivisionsCache.set(topDivId, subDivisions);
        })
      );
    }
  }
}
