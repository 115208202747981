import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { asyncScheduler, Subject, takeUntil } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { LanguageService } from '@app/core/services';
import { LanguageUtils } from '@app/ui-kit/shared/utils/language.utils';
import { CUSTOM_DATE_FORMAT } from '@app/ui-kit/shared/components/controls/year-picker/date-format';
import { IconComponent } from '@app/ui-kit/shared/components/indicators';

@Component({
  selector: 'app-year-picker',
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent, MatDatepickerModule, ReactiveFormsModule],
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: YearPickerComponent,
      multi: true,
    },
    { provide: DateAdapter, useClass: LuxonDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
  ],
})
export class YearPickerComponent implements OnInit, OnDestroy {
  public date = new FormControl(DateTime.now());
  public readonly minDate = DateTime.fromObject({ year: 2000 });
  public readonly maxDate = DateTime.now();
  @ViewChild('picker') datePicker: MatDatepicker<DateTime>;
  private _value: DateTime;
  private destroy$ = new Subject<void>();

  public set value(value: DateTime) {
    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }

  public get value(): DateTime {
    return this._value;
  }

  constructor(private adapter: DateAdapter<LuxonDateAdapter>, private langService: LanguageService) {}

  public onChange: (val: DateTime) => undefined = () => undefined;
  public onTouch: (val: DateTime) => undefined = () => undefined;

  public registerOnChange(fn: (val: DateTime) => undefined): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => undefined): void {
    this.onTouch = fn;
  }

  public writeValue(date: DateTime): void {
    this.value = date.startOf('month');
  }

  public ngOnInit(): void {
    this.langService.currentLanguage$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      this.adapter.setLocale(LanguageUtils.getLocale(lang));
    });

    this.date.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((date) => {
      if (date) {
        this.writeValue(date);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectYear(date: DateTime, picker?: MatDatepicker<LuxonDateAdapter>): void {
    // workaround for avoid blinking before picker close
    if (picker) {
      picker.close();
      picker.panelClass = 'calendar-panel-hidden';
      asyncScheduler.schedule(() => (picker.panelClass = ''), 0);
    }
    this.date.setValue(date, { emitEvent: false });
    this.writeValue(date);
  }

  public toPrevYear(): void {
    if (this.date.value) {
      const newYear = this.date.value?.minus({ year: 1 });
      if (newYear?.get('year') >= this.minDate.year) {
        this.date.patchValue(newYear);
      }
    }
  }

  public toNextYear(): void {
    if (this.date.value) {
      const newYear = this.date.value?.plus({ year: 1 });
      if (newYear?.get('year') <= this.maxDate.year) {
        this.date.patchValue(newYear);
      }
    }
  }
}
