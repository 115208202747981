import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services';
import { AppSection } from '@app/core/models/enums/app-section.enum';
import { SalarySection } from '@app/core/models/enums/salary-section.enum';

export const defaultRedirectGuard = (): boolean => {
  const auth = inject(AuthenticationService);
  const router = inject(Router);
  const user = auth.getUser();
  if (user.canViewAll()) {
    router.navigate([`/${AppSection.SALARIES}/${SalarySection.HR}`]);
  } else if (user.canViewFullSalarySection()) {
    router.navigate([`/${AppSection.SALARIES}/${SalarySection.HR}`]);
  } else if (user.canViewSushiSalarySection()) {
    router.navigate([`/${AppSection.SALARIES}/${SalarySection.SUSHI_MANAGER}`]);
  } else if (user.canViewTimesheetSection()) {
    router.navigate([`/${AppSection.TIMESHEET}`]);
  } else if (user.canViewTaxesSection()) {
    router.navigate([`/${AppSection.TAXES}`]);
  } else {
    router.navigate([`/${AppSection.PROFILE}`]);
  }
  return true;
};
