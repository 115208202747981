<div class="language-select" *ngIf="isDesktop$ | async; else mobileSelect">
  <div class="language-select__current">
    <span>{{ currentLanguage$ | async }}</span>
    <app-icon class="language-select__icon" name="arrow-back-new-rounded-down"></app-icon>
  </div>
  <ul class="language-select__list">
    <li
      class="language-select__item"
      *ngFor="let language of languages"
      (click)="switchLanguage(language)">
      {{ language === 'uk' ? 'ua' : language }}
    </li>
  </ul>
</div>

<ng-template #mobileSelect>
  <div class="language-select-mobile" (click)="openLanguageDialog()">
    {{ currentLanguage$ | async }}
  </div>
</ng-template>
