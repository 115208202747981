import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { tap } from 'rxjs';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LayoutService } from '@app/core/services';

@Component({
  selector: 'app-success-box',
  templateUrl: './success-box.component.html',
  styleUrls: ['./success-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
})
export class SuccessBoxComponent {
  @Input() public message = '';
  public isDesktop$ = this.layoutService.isDesktop$.pipe(tap(() => this.cdk.detectChanges()));

  constructor(
    private layoutService: LayoutService,
    private breakpointObserver: BreakpointObserver,
    private cdk: ChangeDetectorRef
  ) {}
}
