import { ICommentResponse } from '@app/core/api';
import { Comment } from '@app/core/domain';

export const buildComment = (input: ICommentResponse): Comment => {
  const result = new Comment();
  result.id = input.id;
  result.comment = input.comment;
  result.amount = input.amount;
  result.commentType = input.commentType;
  result.commentDate = input.commentDate;
  result.confirmationDate = input.confirmationDate;
  result.confirmationType = input.confirmationType;
  return result;
};
