<app-movable-inline-dialog [header]="(config.header | translate) ?? ' '" [width]="config.width ?? 716">
  <div class="content" *ngIf="history$ | async as history else loader">
    <ng-container *ngIf="config.editable !== false">
      <p class="info" *ngIf="config.info as info">
        <strong>{{info.name}}</strong>
        <span>{{info.position}}</span>
      </p>
      <form class="form" [formGroup]="config.formGroup">
        <div class="fields">
          <ng-container *ngTemplateOutlet="formTemplate; context: { $implicit: config.formGroup }"></ng-container>
        </div>

        <div class="form__actions" *ngIf="!config.showFooterWhenItemSelected || (config.showFooterWhenItemSelected && formGroup.value.id)">
          <app-action-button *ngIf="config.formGroup.value.id && !config.hideDeleteButton; else cancelButton"
                             mode="light" class="form__action" [label]="'delete' | translate" (click)="deleteData()">
          </app-action-button>
          <ng-template #cancelButton>
            <app-action-button mode="light" class="form__action" [label]="'cancel' | translate" (click)="clear()">
            </app-action-button>
          </ng-template>
          <app-action-button mode="light" class="form__action" [label]="'apply' | translate" (click)="saveData()">
          </app-action-button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="history?.length">
      <div class="divider" *ngIf="config.editable !== false"></div>
      <div class="history-table">
        <div class="history-table__header" *ngIf="config.editable !== false">{{'history' | translate}}</div>
        <div class="history-table__table">
          <app-dynamic-table
            class="table"
            [data]="history"
            [columns]="config.historyColumns"
            [rows]="historyRowsConfig"
            (rowSelect)="onHistoryItemSelect($event)"
          ></app-dynamic-table>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!history?.length && config.editable === false" class="no-data">{{'noData' | translate}}</div>
  </div>
</app-movable-inline-dialog>

<ng-template #loader>
  <div class="loader-container">
    <app-loader [isFullscreen]="false"></app-loader>
  </div>
</ng-template>

